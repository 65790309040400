import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getNotificationsSuccess: ["response"],
  getNotificationsFailure: ["error"],
  getNotificationsRequest: [""],

  updateNotificationStatusSuccess: ["response"],
  updateNotificationStatusFailure: ["error"],
  updateNotificationStatusRequest: ["data"],

  setNotificationState: ["key", "value"],
});

export const NotificationTypes = Types;

export const NotificationAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  notifications: [],
  isDialogOpen: false,
  isNotificationStatusUpdated: false,
};

export const getNotificationsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getNotificationsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      notifications: response.data,
      isLoading: false,
    };
  }
};

export const getNotificationsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const updateNotificationStatusRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateNotificationStatusSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      isNotificationStatusUpdated: true,
      isLoading: false,
    };
  }
};

export const updateNotificationStatusFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setNotificationState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,

  [Types.UPDATE_NOTIFICATION_STATUS_REQUEST]: updateNotificationStatusRequest,
  [Types.UPDATE_NOTIFICATION_STATUS_SUCCESS]: updateNotificationStatusSuccess,
  [Types.UPDATE_NOTIFICATION_STATUS_FAILURE]: updateNotificationStatusFailure,

  [Types.SET_NOTIFICATION_STATE]: setNotificationState,
});
