import { Constants } from "constants/Constants";
// import { ContactTypeEnum } from "enumerations/ContactTypeEnum";
// import { VerificationStatusTypeEnum } from "enumerations/VerificationStatusTypeEnum";
import { createReducer, createActions } from "reduxsauce";
import { setItemToLocalStorage } from "utils/LocalStorageUtils";

const { Types, Creators } = createActions({
  newUserNameInfoSuccess: ["response"],
  newUserNameInfoFailure: ["error"],
  newUserNameInfoRequest: ["data"],

  setNewUserNameInfoState: ["key", "value"],
  resetNewUserNameInfoState: [""],
});

export const NewUserNameInfoTypes = Types;

export default Creators; // check creators logic in changepassword admin app

export const INITIAL_STATE = {
  error: null,
  isLoggedIn: false,
  isRegistrationSuccessDialogOpen: false,
  isLoading: false,
};

export const newUserNameInfoRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const newUserNameInfoSuccess = (state: any, action: any) => {
  const response = action.response;
  setItemToLocalStorage(Constants.FIRSTNAME, response?.data?.firstName);
  setItemToLocalStorage(Constants.LASTNAME, response?.data?.lastName);
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      isRegistrationSuccessDialogOpen: true,
    };
  }
};

export const newUserNameInfoFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setNewUserNameInfoState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const resetNewUserNameInfoState = (state: any, action: any) => {
  return {
    ...state,
    error: null,
    isLoggedIn: false,
    isRegistrationSuccessDialogOpen: false,
    isLoading: false,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.NEW_USER_NAME_INFO_SUCCESS]: newUserNameInfoSuccess,
  [Types.NEW_USER_NAME_INFO_FAILURE]: newUserNameInfoFailure,
  [Types.NEW_USER_NAME_INFO_REQUEST]: newUserNameInfoRequest,

  [Types.SET_NEW_USER_NAME_INFO_STATE]: setNewUserNameInfoState,
  [Types.RESET_NEW_USER_NAME_INFO_STATE]: resetNewUserNameInfoState,
});
