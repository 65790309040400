import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCommingSoonProjectSuccess: ["response"],
  getCommingSoonProjectFailure: ["error"],
  getCommingSoonProjectRequest: ["data"],
});

export const CommingSoonProjectType = Types;

export const CommingSoonProjectAction = Creators;

export const INITIAL_STATE = {
  commingSoonProjects: [],
  error: null,
  isLoading: "",
};

export const getCommingSoonProjectRequest = (state: any) => {

  return {
    ...state,
    isLoading: true,
  };
};

export const getCommingSoonProjectSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response.data.pageManagementsOrCollectionTwoModels) {
    return {
      ...state,
      commingSoonProjects: response.data,
      error: null,
      isLoading: false,
    };
  }
  //   if (!response.data.pageManagementsOrCollectionTwoModels) {
  //     return {
  //       ...state,
  //       error: null,
  //       pageData: response.data,
  //       isLoading: false,
  //       isSuccess: true,
  //     };
  //   }
  //   return {
  //     ...state,
  //     isLoading: false,
  //   };
};

export const getCommingSoonProjectFailure = (state: any, action: any) => {
  return {
    ...state,
    error: action.error.message,
    isLoading: false,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COMMING_SOON_PROJECT_SUCCESS]: getCommingSoonProjectSuccess,
  [Types.GET_COMMING_SOON_PROJECT_FAILURE]: getCommingSoonProjectFailure,
  [Types.GET_COMMING_SOON_PROJECT_REQUEST]: getCommingSoonProjectRequest,
});
