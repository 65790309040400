export const RouteConfigurations: { [key: string]: string } = {
  landing: "/",
  login: "/login",
  home: "/home",
  promise: "/promise",
  myService: "/my-service",
  promiseDetails: "details",
  portfolio: "/portfolio",
  portfolioDetails: "/portfolio/portfolio-details",
  investments: "/investments",
  investmentDetails: "/investments/investment-details",
  investmentOpportunityDoc: "/investments/investment-opportunity-doc",
  newLaunch: "/investments/newLaunch",
  allInvestments: "/investments/allInvestments",
  soldProject: "/investments/soldProject",
  fastSelling: "/investments/fastSelling",
  investmentLocation: "/investments/investment-location",
  landSkuList: "/investments/land-sku-list",
  profile: "/profile",
  testimonials: "/home/testimonials",
  updates: "/home/updates",
  insights: "/home/insights",
  chat: "/chat",
  investmentSummaryMap:
    "/investments/investment-details/investment-summary-map",
  myAccount: "/profile/my-account",
  securityAndSettings: "/profile/security-and-settings",
  helpCenter: "/profile/help-center",
  facilityManagement: "/facility-management",
  faqs: "/faqs",
  profileFaqs: "/profile-faqs",
  pageNotFound404: "*",
  aboutUs: "/profile/about-us",
  paymentHistory: "/payment-history",
  documentDetails: "/document-details",
  search: "/search",
  projectTimeline: "/project-timeline",
  mediaGallery: "/media-gallery",
  bookingJourney: "/booking-journey",
  editProfile: "/edit-profile",
  userDetails: "/user-detail",
  profileDetails: "/profile-details",
  investMentDetail: "/investment-details/:title",
  investProjectDetail: "/invest-project-detail",
  iventoryBuckets: "/inventory-buckets",
  redirectToPaymentGateway: "/redirect-to-paymentGateway",
  viewOnMap: "/view-on-map",
  whyToInvest: "/:project/why-to-invest/",
  preLoginAboutUs: "/about-us",
  preLoginPromise: "/promise",
  mediaPr: "/media-pr",
  mediaPrDetail: "/media-pr-detail",
  lifeAtHoabl: "/life-at-hoabl",
  ayodhya: "/ayodhya",
  submited: "/submited",
  ayodhyaLatestNews: "/ayodhya-latest-news",
  goa: "/goa",
  ayodhyaPartnerSubmmit: "/ayodhya/partnersummit",
  ayodhyaPartnerSubmmitThankyou: "/ayodhya/partnersummit/thankyou",
  thankYou: "/thank-you",
  TAC: "/terms-and-conditions",
  scanQR: "/rera-codes",

  accountDeletion: "/account-deletion",
};
