import MediaAssets from "assets";
import { useEffect, useState } from "react";
import styles from "./Promises.module.css";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import PromiseCard from "./promise-card/PromiseCard";
import { numOfItems } from "utils/helperFunctions";
import { Avatar, Theme, Typography, useMediaQuery, Box } from "@mui/material";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useNavigate } from "react-router-dom";
import Menu from "pages/menu/Menu";
import { RouteConfigurations } from "routes/RouteConfigurations";

import MenuAccordion from "pages/landing/menu-accordion/MenuAccordion";
import DesktopPromise from "./desktop/DesktopPromise";
import LandingFooter from "pages/landing/landing-footer/LandingFooter";
import Project from "pages/landing/projects/projects";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getPreLoginPageAction } from "redux-container/pre-login-page-management-redux/PreLoginPageManagementRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getPromiseRequest: (pageType: string) =>
      dispatch(getPageAction.getPromiseRequest(pageType)),
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),

    getPreLoginPageDetails: (params: any) =>
      dispatch(getPreLoginPageAction.getPreLoginPagesRequest(params)),
  };
};

const Promises = () => {
  const [clicked, setClicked] = useState<null | number>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [stateOne, setStateOne] = useState(false);
  const [stateTwo, setstateTwo] = useState(false);
  const [stateThree, setStateThree] = useState(false);
  const [stateFour, setStateFour] = useState(false);
  const [ind, setInd] = useState(0);

  const promisesHandler = (data: number) => {
    setClicked(clicked === data ? null : data);
  };
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getPromiseRequest, setPageState, getPreLoginPageDetails } =
    actionDispatch(useDispatch());
  const { pageData, promiseData, isLoading, error } = useSelector(
    (state: any) => ({
      pageData: state?.preLoginPageManagement?.pageData,
      isLoading: state?.preLoginPageManagement?.isLoading,
      error: state?.preLoginPageManagement?.error,
      promiseData: state?.preLoginPageManagement?.pageData?.homePagesOrPromises,
    })
  );

  const icons = [
    MediaAssets.security_new,
    MediaAssets.liquidity_new,
    MediaAssets.transparency_new,
    MediaAssets.wealth_new,
  ];

  function compareByPriority(item1: any, item2: any) {
    return item1.priority - item2.priority;
  }
  promiseData?.sort(compareByPriority);
  const handlePromiseDrawer = (promise: (typeof promiseData)[0]) => () => {
    setPageState("promiseDetails", promise);
    setPageState("isDialogOpen", true);
  };

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getPromiseRequest(PageTypeEnum.Promises);
      const pageType = PageTypeEnum?.HoABL;
      getPreLoginPageDetails(pageType);
    }
    return () => {
      cancelEffect = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const promiseBackHandler = () => {
    navigate(RouteConfigurations.home);
  };

  const toogleClassOne = (index: any) => {
    setInd(index);
    if (stateOne === true) {
      setStateOne(false);
    } else {
      setStateOne(true);
    }
  };

  return (
    <>
      {showMenu ? (
        <Menu setShowMenu={setShowMenu} />
      ) : (
        <section>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.desktopPromise}>
                <DesktopPromise />
              </div>
              <Box
                mb={6}
                style={{ padding: "15px 15px 2rem" }}
                className={styles.boxStyle}
              >
                <div className={styles["promises-title"]}>
                  <div className={styles.promises_left_style}>
                    <img
                      src={MediaAssets.profile_arrow_icon}
                      onClick={promiseBackHandler}
                    />
                    <Typography className={styles.promises_left_content}>
                      Promises
                    </Typography>
                  </div>
                  <div>
                    <img src={MediaAssets.profile_search_icon} />
                    <img
                      src={MediaAssets.profile_hamburger_icon}
                      onClick={() => setShowMenu(true)}
                    />
                  </div>
                </div>

                <div className={styles["about-promise-holder"]}>
                  <p>
                    {pageData?.promiseSection?.aboutPromises?.subDescription}
                  </p>
                </div>
                <div className={styles.forMobile}>
                  <div className={styles.wrapper}>
                    {promiseData &&
                      promiseData.map((promise: any, index: number) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                stateOne && index === ind
                                  ? styles.openMenu
                                  : styles.beforeAfter
                              }
                            >
                              <div className={styles.inside}>
                                <div className={styles.menu}>
                                  <div className={styles.shieldWithTitle}>
                                    <LazyLoadImage
                                      src={icons[index]}
                                      alt="Landing page"
                                      className={`shield${index}`}
                                      id={`shield${index}`}
                                      width={40}
                                      style={{
                                        marginLeft:
                                          index === 2
                                            ? "-85px"
                                            : ` ${index === 3 ? "8px" : ""}`,
                                        width: "100px",
                                        lineHeight: 0,
                                      }}
                                    />
                                    <p
                                      className={`text${index}`}
                                      style={{
                                        marginLeft: index === 1 ? "10px" : "",
                                        fontSize: "20px",
                                        lineHeight: 0,
                                      }}
                                    >
                                      {promise.name}
                                    </p>
                                  </div>

                                  <div
                                    className={styles.descp}
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Futura Md BT",
                                    }}
                                  >
                                    {promise.shortDescription}
                                  </div>
                                  <div className={styles.arrowWrapper}>
                                    <LazyLoadImage
                                      src={MediaAssets.arrow}
                                      alt="Landing page"
                                      width={24}
                                      onClick={() => {
                                        toogleClassOne(index);
                                      }}
                                      className={`arrow${index}`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>

                <div className={styles.promises_end}>
                  Please read our{" "}
                  <Link to="/" className={styles.promises_end_link}>
                    Terms & Conditions
                  </Link>{" "}
                  to understand the full extent & application of the promises
                  made.
                </div>

                {/* {promiseData && !!promiseData.length && (
              <div className={styles["promises-carousel-holder"]}>
                <h2>{pageData?.promiseSection?.promiseSectionHeading}</h2>

                <MantineCarousel
                  containermargin={0}
                  breakpoints={[
                    { maxWidth: "xl", slideSize: numOfItems(4.5) },
                    { maxWidth: "lg", slideSize: numOfItems(3.4) },
                    { maxWidth: "md", slideSize: numOfItems(2.3) },
                    { maxWidth: "sm", slideSize: numOfItems(2) },
                    { maxWidth: "xs", slideSize: numOfItems(2) },
                  ]}
                  height={isSmallScreen ? 280 : 360}
                  slideSize={numOfItems(5)}
                >
                  {promiseData?.map((promise: (typeof promiseData)[0]) => {
                    return (
                      <Carousel.Slide>
                        <PromiseCard
                          isarrowrequire={true}
                          name={promise?.name}
                          iconsrc={promise?.displayMedia?.value?.url}
                          description={promise?.shortDescription}
                          handlecardclick={handlePromiseDrawer(promise)}
                        />
                      </Carousel.Slide>
                    );
                  })}
                </MantineCarousel>
              </div>
            )}
            {pageData?.promiseSection?.aboutPromises?.isDisclaimerActive && (
              <Typography
                variant={isSmallScreen ? "body2" : "body1"}
                className={styles["disclaimer-text"]}
              >
                {pageData?.promiseSection?.disclaimer}
              </Typography>
            )} */}
              </Box>
            </>
          )}
          <LandingFooter />
          <Project />

          <MuiSnackbar
            shouldOpen={error !== null ? true : false}
            message={error}
            isSuccess={false}
            closeHandler={() => setPageState("error", null)}
          />
        </section>
      )}
    </>
  );
};

export default Promises;
