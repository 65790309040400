import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { getProjectContentsAction } from "redux-container/project-contents/ProjectContents";

export function* getProjectContentsList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectContent, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        getProjectContentsAction.getProjectContentsSuccess(response.data)
      );
    } else {
      yield put(
        getProjectContentsAction.getProjectContentsFailure(response.data)
      );
    }
  } catch (error) {
    yield put(getProjectContentsAction.getProjectContentsFailure(error));
  }
}

export function* getProjectContentById(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProjectContentById, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        getProjectContentsAction.getProjectContentByIdSuccess(response.data)
      );
    } else {
      yield put(
        getProjectContentsAction.getProjectContentByIdFailure(response.data)
      );
    }
  } catch (error) {
    yield put(getProjectContentsAction.getProjectContentByIdFailure(error));
  }
}

export function* getMediaByCategoryAndProjectId(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.getMediaByCategoryAndProjectId,
      action.data
    );
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        getProjectContentsAction.getMediaByCategoryAndProjectIdSuccess(
          response.data
        )
      );
    } else {
      yield put(
        getProjectContentsAction.getMediaByCategoryAndProjectIdFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      getProjectContentsAction.getMediaByCategoryAndProjectIdFailure(error)
    );
  }
}
