import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useClevertap from "./useClevertap";
import { RouteConfigurations } from "routes/RouteConfigurations";

export enum EventNameType {
  WEBSITE_LAUNCHED = "WEBSITE_LAUNCHED",
  PAGE_VIEWED = "PAGE_VIEWED",
  UTM_VISITED = "UTM_VISITED",
  PROJECT_VIEWED = "PROJECT_VIEWED",
  VIEW_PROJECT_MEDIA = "VIEW_PROJECT_MEDIA",
  MAP_VIEWED = "MAP_VIEWED",
  DOWNLAOD_CATALAGUE = "DOWNLOAD_CATALOGUE",
  LAED_CAPTURE = "LEAD_CAPTURE",
  RERA_CODE_SEARCH = "RERA_CODE_SEARCH",
}

const urlToPageName = (pathname: string) => {
  let pageName = "UNKNOWN";
  for (const key of Object.keys(RouteConfigurations)) {
    if (RouteConfigurations?.[key] === pathname) {
      pageName = key;
    }
  }
  return pageName;
};

const UseRunCleverTapEvents = () => {
  const { pathname, search } = useLocation();
  const { clevertapPushEvent } = useClevertap();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    if (pathname === "/home" || pathname == "/") return;
    console.log(pathname, "pathname");

    if (pathname == "/") {
      clevertapPushEvent(EventNameType.WEBSITE_LAUNCHED, {
        homepage: "visited",
      });
    } else {
      let pageName = urlToPageName(pathname);
      clevertapPushEvent(EventNameType.PAGE_VIEWED, {
        "Page Name": pageName,
        "Page Url": pathname,
      });
    }
    let isUtm = !!queryParams.get("utm_source");
    if (isUtm) {
      const paramsArray = Array.from(queryParams.entries());
      const params: { [key: string]: string } = {};
      paramsArray.forEach(([key, value]) => {
        params[key] = value;
      });
      clevertapPushEvent(EventNameType.UTM_VISITED, params);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname == "/") {
      clevertapPushEvent(EventNameType.WEBSITE_LAUNCHED, {
        homepage: "visited",
      });
    }
  }, []);

  return null;
};

export default UseRunCleverTapEvents;
