import { Constants } from "constants/Constants";
const BaseUrl = Constants.ASSETS_BASE_URL;
const IMAGE_PATH = Constants.ASSETS_IMAGE_PATH;
const VIDEO_PATH = Constants.ASSETS_VIDEO_PATH;

const MediaAssets: any = {
  ic_info: `${BaseUrl}${IMAGE_PATH}/ic_info.png`,
  ic_logo_white: `${BaseUrl}${IMAGE_PATH}/ic_logo_white.png`,
  ic_refer_friend: `${BaseUrl}${IMAGE_PATH}/ic_refer_friend.png`,
  ic_playstore: `${BaseUrl}${IMAGE_PATH}/ic_playstore.png`,
  ic_manage_land: `${BaseUrl}${IMAGE_PATH}/ic_manage_land.png`,
  ic_playstore_download: `${BaseUrl}${IMAGE_PATH}/ic_playstore_download.png`,
  ic_appstore_download: `${BaseUrl}${IMAGE_PATH}/ic_appstore_download.png`,
  ic_appstore: `${BaseUrl}${IMAGE_PATH}/ic_appstore.png`,
  ic_mobile_app: `${BaseUrl}${IMAGE_PATH}/ic_mobile_app.png`,
  ic_insight: `${BaseUrl}${IMAGE_PATH}/ic_insight.png`,
  ic_chat: `${BaseUrl}${IMAGE_PATH}/ic_chat.png`,
  ic_home_inactive: `${BaseUrl}${IMAGE_PATH}/ic_home_inactive.png`,
  ic_portfolio_inactive: `${BaseUrl}${IMAGE_PATH}/ic_portfolio_inactive.png`,
  ic_promise_inactive: `${BaseUrl}${IMAGE_PATH}/ic_promise_inactive.png`,
  ic_investments_inactive: `${BaseUrl}${IMAGE_PATH}/ic_investments_inactive.png`,
  ic_profile_inactive: `${BaseUrl}${IMAGE_PATH}/ic_profile_inactive.png`,
  ic_portfolio_active: `${BaseUrl}${IMAGE_PATH}/ic_portfolio_active.png`,
  ic_promise_active: `${BaseUrl}${IMAGE_PATH}/ic_promise_active.png`,
  ic_home_active: `${BaseUrl}${IMAGE_PATH}/ic_home_active.png`,
  ic_investments_active: `${BaseUrl}${IMAGE_PATH}/ic_investments_active.png`,
  ic_profile_active: `${BaseUrl}${IMAGE_PATH}/ic_profile_active.png`,
  ic_promise_banner: `${BaseUrl}${IMAGE_PATH}/ic_full_promise_banner.png`,
  ic_promise_banner_small: `${BaseUrl}${IMAGE_PATH}/ic_small_promise_banner.png`,
  ic_wealth: `${BaseUrl}${IMAGE_PATH}/ic_wealth.png`,
  ic_security: `${BaseUrl}${IMAGE_PATH}/ic_security.png`,
  ic_liquidity: `${BaseUrl}${IMAGE_PATH}/ic_liquidity.png`,
  ic_briefcase: `${BaseUrl}${IMAGE_PATH}/ic_briefcase.png`,
  ic_preposition_one: `${BaseUrl}${IMAGE_PATH}/ic_preposition_one.png`,
  ic_preposition_two: `${BaseUrl}${IMAGE_PATH}/ic_preposition_two.png`,
  ic_preposition_three: `${BaseUrl}${IMAGE_PATH}/ic_preposition_three.png`,
  ic_update: `${BaseUrl}${IMAGE_PATH}/ic_update.png`,
  ic_my_account: `${BaseUrl}${IMAGE_PATH}/ic_my_account.png`,
  ic_security_settings: `${BaseUrl}${IMAGE_PATH}/ic_security_settings.png`,
  ic_help_center: `${BaseUrl}${IMAGE_PATH}/ic_help_center.png`,
  ic_facility_management: `${BaseUrl}${IMAGE_PATH}/ic_facility_management.png`,
  ic_edit: `${BaseUrl}${IMAGE_PATH}/ic_edit.png`,
  ic_privacy_policy: `${BaseUrl}${IMAGE_PATH}/ic_privacy_policy.png`,
  ic_faq: `${BaseUrl}${IMAGE_PATH}/ic_faq.png`,
  ic_about_us: `${BaseUrl}${IMAGE_PATH}/ic_about_us.png`,
  ic_feedback: `${BaseUrl}${IMAGE_PATH}/ic_feedback.png`,
  ic_profit: `${BaseUrl}${IMAGE_PATH}/ic_profit.png`,
  ic_siren: `${BaseUrl}${IMAGE_PATH}/ic_siren.png`,
  ic_promotions: `${BaseUrl}${IMAGE_PATH}/ic_promotions.png`,
  ic_bg_image: `${BaseUrl}${IMAGE_PATH}/ic_bg_image.png`,
  ic_home_setting: `${BaseUrl}${IMAGE_PATH}/ic_home_setting.png`,
  ic_gmail: `${BaseUrl}${IMAGE_PATH}/ic_gmail.png`,
  ic_twitter: `${BaseUrl}${IMAGE_PATH}/ic_twitter.png`,
  ic_whatsapp: `${BaseUrl}${IMAGE_PATH}/ic_whatsapp.png`,
  ic_linkedin: `${BaseUrl}${IMAGE_PATH}/ic_linkedin.png`,
  ic_no_document: `${BaseUrl}${IMAGE_PATH}/ic_no_document.png`,
  ic_payment_history: `${BaseUrl}${IMAGE_PATH}/ic_payment_history.png`,
  ic_right_arrow: `${BaseUrl}${IMAGE_PATH}/ic_right_arrow.png`,
  ic_chat_icon: `${BaseUrl}${IMAGE_PATH}/ic_chat_icon.png`,
  ic_chat_refresh: `${BaseUrl}${IMAGE_PATH}/ic_chat_refresh.png`,
  ic_docs: `${BaseUrl}${IMAGE_PATH}/ic_docs.png`,
  ic_active_bad: `${BaseUrl}${IMAGE_PATH}/ic_active_bad.png`,
  ic_active_good: `${BaseUrl}${IMAGE_PATH}/ic_active_good.png`,
  ic_active_ok: `${BaseUrl}${IMAGE_PATH}/ic_active_ok.png`,
  ic_active_poor: `${BaseUrl}${IMAGE_PATH}/ic_active_poor.png`,
  ic_active_excellent: `${BaseUrl}${IMAGE_PATH}/ic_active_excellent.png`,
  ic_inactive_bad: `${BaseUrl}${IMAGE_PATH}/ic_inactive_bad.png`,
  ic_inactive_good: `${BaseUrl}${IMAGE_PATH}/ic_inactive_good.png`,
  ic_inactive_ok: `${BaseUrl}${IMAGE_PATH}/ic_inactive_ok.png`,
  ic_inactive_poor: `${BaseUrl}${IMAGE_PATH}/ic_inactive_poor.png`,
  ic_inactive_excellent: `${BaseUrl}${IMAGE_PATH}/ic_inactive_excellent.png`,
  ic_upload: `${BaseUrl}${IMAGE_PATH}/ic_upload.png`,
  ic_remove: `${BaseUrl}${IMAGE_PATH}/ic_remove.png`,
  ic_close: `${BaseUrl}${IMAGE_PATH}/ic_close.png`,
  ic_close_white: `${BaseUrl}${IMAGE_PATH}/ic_close_white.png`,
  ic_profile_placeholder: `${BaseUrl}${IMAGE_PATH}/ic_profile_placeholder.png`,
  ic_party_left: `${BaseUrl}${IMAGE_PATH}/ic_party_left.png`,
  ic_party_right: `${BaseUrl}${IMAGE_PATH}/ic_party_right.png`,
  ic_logo_gold: `${BaseUrl}${IMAGE_PATH}/ic_logo_gold.png`,
  ic_my_services: `${BaseUrl}${IMAGE_PATH}/ic_my_services.png`,
  ic_my_service_active: `${BaseUrl}${IMAGE_PATH}/ic_my_service_active.png`,
  ic_my_service_inactive: `${BaseUrl}${IMAGE_PATH}/ic_my_service_inactive.png`,
  ic_please_wait: `${BaseUrl}${IMAGE_PATH}/ic_please_wait.png`,
  ic_ok_success: `${BaseUrl}${IMAGE_PATH}/ic_ok_success.png`,
  ic_green_circle: `${BaseUrl}${IMAGE_PATH}/ic_green_circle.png`,
  ic_no_project: `${BaseUrl}${IMAGE_PATH}/ic_no_project.png`,
  ic_rupee_coin: `${BaseUrl}${IMAGE_PATH}/ic_rupee_coin.png`,
  ic_rupee_disabled: `${BaseUrl}${IMAGE_PATH}/ic_rupee_disabled.png`,
  ic_upwards_arrow: `${BaseUrl}${IMAGE_PATH}/ic_upward_arrow.png`,
  ic_download_icon: `${BaseUrl}${IMAGE_PATH}/ic_download_icon.png`,
  ic_red_close: `${BaseUrl}${IMAGE_PATH}/ic_red_close.png`,
  ic_green_tick: `${BaseUrl}${IMAGE_PATH}/ic_green_tick.png`,
  ic_send_arrow: `${BaseUrl}${IMAGE_PATH}/ic_send_arrow.png`,
  ic_next_arrow: `${BaseUrl}${IMAGE_PATH}/ic_next_arrow.png`,
  ic_source_location: `${BaseUrl}${IMAGE_PATH}/ic_source_location.png`,
  ic_destination_location: `${BaseUrl}${IMAGE_PATH}/ic_destination_location.png`,
  ic_promise_card_bg: `${BaseUrl}${IMAGE_PATH}/ic_promise_card_bg.png`,
  ic_button_next_arrow: `${BaseUrl}${IMAGE_PATH}/ic_button-next-arrow.svg`,
  ic_button_next_arrow_black: `${BaseUrl}${IMAGE_PATH}/ic_next_arrow_black.png`,
  my_account: `${BaseUrl}${IMAGE_PATH}/my_account.svg`,
  setting: `${BaseUrl}${IMAGE_PATH}/setting.svg`,
  help_center: `${BaseUrl}${IMAGE_PATH}/help_center.svg`,
  about_us: `${BaseUrl}${IMAGE_PATH}/about_us.svg`,
  my_service: `${BaseUrl}${IMAGE_PATH}/my_service.svg`,
  logout_btn: `${BaseUrl}${IMAGE_PATH}/logout_btn.svg`,
  user_profile: `${BaseUrl}${IMAGE_PATH}/user_profile.svg`,
  camera_icon: `${BaseUrl}${IMAGE_PATH}/camera_icon.svg`,
  home_active_icon: `${BaseUrl}${IMAGE_PATH}/home_active_icon.svg`,
  home_deactive_icon: `${BaseUrl}${IMAGE_PATH}/home_deactive_icon.svg`,
  invest_icon_active: `${BaseUrl}${IMAGE_PATH}/invest_icon_active.svg`,
  invest_icon_deactive: `${BaseUrl}${IMAGE_PATH}/invest_icon_deactive.svg`,
  portofolio_active_icon: `${BaseUrl}${IMAGE_PATH}/portofolio_active_icon.svg`,
  portofolio_deactive_icon: `${BaseUrl}${IMAGE_PATH}/portfolio_deactive_icon.svg`,
  promises_active_icon: `${BaseUrl}${IMAGE_PATH}/promises_active_icon.svg`,
  promises_deactive_icon: `${BaseUrl}${IMAGE_PATH}/promises_deactive_icon.svg`,
  profile_active_icon: `${BaseUrl}${IMAGE_PATH}/profile_active_icon.svg`,
  profile_deactive_icon: `${BaseUrl}${IMAGE_PATH}/profile_deactive_icon.svg`,
  ic_msg: `${BaseUrl}${IMAGE_PATH}/ic_msg.svg`,
  ic_call: `${BaseUrl}${IMAGE_PATH}/ic_call.svg`,
  ic_email: `${BaseUrl}${IMAGE_PATH}/ic_email.svg`,
  edit_camera: `${BaseUrl}${IMAGE_PATH}/edit_camera.png`,
  edit_profile_icon: `${BaseUrl}${IMAGE_PATH}/edit_profile_icon.svg`,
  edit_remove_icon: `${BaseUrl}${IMAGE_PATH}/edit_remove_icon.svg`,
  edit_deactive_icon: `${BaseUrl}${IMAGE_PATH}/edit_deactive_icon.svg`,
  edit_active_icon: `${BaseUrl}${IMAGE_PATH}/edit_active_icon.svg`,
  tick_dialog_gif: `${BaseUrl}${IMAGE_PATH}/tick_dialog_gif.gif`,
  profile_arrow_icon: `${BaseUrl}${IMAGE_PATH}/profile_arrow_icon.svg`,
  profile_search_icon: `${BaseUrl}${IMAGE_PATH}/profile_search_icon.svg`,
  profile_hamburger_icon: `${BaseUrl}${IMAGE_PATH}/profile_hamburger_icon.svg`,
  investmentDetails_home_img: `${BaseUrl}${IMAGE_PATH}/investmentDetails_home_img.svg`,

  //iamges added by kartik
  ic_aboutUs1: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs1.png`,
  ic_aboutUs2: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs2.png`,
  ic_aboutUs3: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs3.png`,
  ic_aboutUs4: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs4.png`,
  ic_aboutUs_Detail1: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs_Detail1.png`,
  ic_aboutUs_Detail2: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs_Detail2.png`,
  ic_aboutUs_Detail3: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs_Detail3.png`,
  ic_aboutUs_Detail4: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs_Detail4.png`,
  ic_group_three_leaves: `${BaseUrl}${IMAGE_PATH}/ic_group_three_leaves.png`,
  ic_arrow_left: `${BaseUrl}${IMAGE_PATH}/ic_arrow_left.svg`,
  ic_hobal_logo: `${BaseUrl}${IMAGE_PATH}/ic_hobal_logo.svg`,
  ic_login_Icon_used: `${BaseUrl}${IMAGE_PATH}/ic_login_Icon_used.svg`,
  ic_next_icon: `${BaseUrl}${IMAGE_PATH}/ic_next_icon.svg`,
  ic_protection_security_tips: `${BaseUrl}${IMAGE_PATH}/ic_protection_security_tips.svg`,
  ic_arrow_back_24px: `${BaseUrl}${IMAGE_PATH}/ic_arrow_back_24px.svg`,
  ic_myAccount_documents: `${BaseUrl}${IMAGE_PATH}/ic_myAccount_documents.svg`,
  ic_myAccount_payment: `${BaseUrl}${IMAGE_PATH}/ic_myAccount_payment.svg`,
  ic_arrow_forward: `${BaseUrl}${IMAGE_PATH}/ic_arrow_forward.svg`,
  ic_aboutUs_background: `${BaseUrl}${IMAGE_PATH}/ic_aboutUs_background.png`,
  ic_security_shield: `${BaseUrl}${IMAGE_PATH}/ic_security_shield.png`,
  image_used: `${BaseUrl}${IMAGE_PATH}/image_used.png`,
  report_security_btn: `${BaseUrl}${IMAGE_PATH}/report_security_btn.svg`,
  ic_invest_firstImage: `${BaseUrl}${IMAGE_PATH}/ic_invest_firstImage.png`,

  ic_check_circle: `${BaseUrl}${IMAGE_PATH}/ic_check_circle.svg`,
  ic_sold_logo: `${BaseUrl}${IMAGE_PATH}/ic_sold_logo.svg`,
  ic_newUserWelcome: `${BaseUrl}${IMAGE_PATH}/ic_newUserWelcome.svg`,
  Ic_button_world_icon: `${BaseUrl}${IMAGE_PATH}/Ic_button_world_icon.svg`,
  ic_card_travel: `${BaseUrl}${IMAGE_PATH}/card_travel.svg`,
  ic_indiaFlag: `${BaseUrl}${IMAGE_PATH}/ic_indiaFlag.svg`,
  ic_arrow_right: `${BaseUrl}${IMAGE_PATH}/ic_arrow_right.svg`,
  ic_newLaunch_header_icon: `${BaseUrl}${IMAGE_PATH}/ic_newLaunch_header_icon.svg`,
  mumbai_goa: `${BaseUrl}${IMAGE_PATH}/mumbai_goa.png`,
  konkan: `${BaseUrl}${IMAGE_PATH}/konkan.png`,
  belapur: `${BaseUrl}${IMAGE_PATH}/belapur.png`,
  why_to_invest: `${BaseUrl}${IMAGE_PATH}/why_to_invest.png`,
  image_used1: `${BaseUrl}${IMAGE_PATH}/image_used1.png`,
  image_used2: `${BaseUrl}${IMAGE_PATH}/image_used2.png`,
  image_used3: `${BaseUrl}${IMAGE_PATH}/image_used3.png`,
  ic_dont_Miss1: `${BaseUrl}${IMAGE_PATH}/ic_dont_Miss1.png`,
  ic_dont_Miss2: `${BaseUrl}${IMAGE_PATH}/ic_dont_Miss2.png`,
  ic_dont_Miss3: `${BaseUrl}${IMAGE_PATH}/ic_dont_Miss3.png`,

  currentInfra: `${BaseUrl}${IMAGE_PATH}/current_infra.png`,
  ic_location_on: `${BaseUrl}${IMAGE_PATH}/ic_location_on.svg`,
  beach: `${BaseUrl}${IMAGE_PATH}/beach.png`,
  temple: `${BaseUrl}${IMAGE_PATH}/temple.png`,
  home_banner: `${BaseUrl}${IMAGE_PATH}/home_banner.svg`,
  content_img: `${BaseUrl}${IMAGE_PATH}/content_img.png`,
  hamburger_menu: `${BaseUrl}${IMAGE_PATH}/hamburger.svg`,
  chevron_up: `${BaseUrl}${IMAGE_PATH}/chevron-up.svg`,
  ic_arrow_upward: `${BaseUrl}${IMAGE_PATH}/ic_arrow_upward.png`,
  instagram: `${BaseUrl}${IMAGE_PATH}/instagram.svg`,
  facebook: `${BaseUrl}${IMAGE_PATH}/facebook.svg`,
  linkedin: `${BaseUrl}${IMAGE_PATH}/linkedin.svg`,
  ic_blog: `${BaseUrl}${IMAGE_PATH}/ic_blog.svg`,
  arrow_left: `${BaseUrl}${IMAGE_PATH}/arrow-left.svg`,
  slider_banner: `${BaseUrl}${IMAGE_PATH}/slider_banner.svg`,
  slider_icon: `${BaseUrl}${IMAGE_PATH}/slider_icon.svg`,
  subtract: `${BaseUrl}${IMAGE_PATH}/Subtract.svg`,
  globe: `${BaseUrl}${IMAGE_PATH}/globe.svg`,
  testimonial_icon: `${BaseUrl}${IMAGE_PATH}/testimonial_icon.svg`,
  testimonial_img: `${BaseUrl}${IMAGE_PATH}/testimonial_img.png`,
  tomorrow_land: `${BaseUrl}${IMAGE_PATH}/tomorrow_land.png`,
  devlop_land: `${BaseUrl}${IMAGE_PATH}/develop_land.png`,
  gradient_top: `${BaseUrl}${IMAGE_PATH}/gradient_top_image.png`,
  menu_icon: `${BaseUrl}${IMAGE_PATH}/menuicon.png`,
  explore_icon: `${BaseUrl}${IMAGE_PATH}/explore_icon.svg`,
  explore_icon_gray: `${BaseUrl}${IMAGE_PATH}/explore_icon_gray.svg`,
  search_icon: `${BaseUrl}${IMAGE_PATH}/material-symbols_search.svg`,
  ongoing_banner: `${BaseUrl}${IMAGE_PATH}/ongoing_banner.png`,
  upcoming_banner: `${BaseUrl}${IMAGE_PATH}/upcoming_banner.png`,
  landing_page_banner: `${BaseUrl}${IMAGE_PATH}/landing_page_banner.svg`,
  blog_two: `${BaseUrl}${IMAGE_PATH}/blog_two.svg`,
  blog_three: `${BaseUrl}${IMAGE_PATH}/blog_three.svg`,
  share_goodness: `${BaseUrl}${IMAGE_PATH}/share_goodness.svg`,
  trending_up_24px: `${BaseUrl}${IMAGE_PATH}/trending_up_24px.svg`,
  shopping_basket_NoPortfolio: `${BaseUrl}${IMAGE_PATH}/shopping_basket_NoPortfolio.svg`,
  promises_small_background: `${BaseUrl}${IMAGE_PATH}/promises_small_background.png`,
  promises_full_card: `${BaseUrl}${IMAGE_PATH}/promises_full_card_bg.png`,
  white_arrow_icon: `${BaseUrl}${IMAGE_PATH}/white_arrow_icon.svg`,
  black_love_icon: `${BaseUrl}${IMAGE_PATH}/black_love_icon.svg`,
  black_search_icon: `${BaseUrl}${IMAGE_PATH}/black_search_icon.svg`,
  club_house: `${BaseUrl}${IMAGE_PATH}/club_house.svg`,
  swimming_house: `${BaseUrl}${IMAGE_PATH}/swimming_pol.svg`,
  investment_coming_soon: `${BaseUrl}${IMAGE_PATH}/investment_coming_soon.svg`,
  video_img_one: `${BaseUrl}${IMAGE_PATH}/video_img_one.svg`,
  video_img_two: `${BaseUrl}${IMAGE_PATH}/video_img_two.svg`,
  music_icon: `${BaseUrl}${IMAGE_PATH}/music_icon.svg`,
  three_d_photo_one: `${BaseUrl}${IMAGE_PATH}/three_d_photo_one.svg`,
  three_d_photo_two: `${BaseUrl}${IMAGE_PATH}/three_d_photo_two.svg`,
  icon_threesixty_view: `${BaseUrl}${IMAGE_PATH}/icono-360-view.svg`,
  ic_settings: `${BaseUrl}${IMAGE_PATH}/settings_24px.png`,
  website_logo: `${BaseUrl}${IMAGE_PATH}/website_logo.svg`,
  web_msg_icon: `${BaseUrl}${IMAGE_PATH}/web_msg_icon.svg`,
  web_notification_icon: `${BaseUrl}${IMAGE_PATH}/web_notification_icon.svg`,
  web_edit_icon: `${BaseUrl}${IMAGE_PATH}/web_edit_icon.svg`,
  web_black_edit_icon: `${BaseUrl}${IMAGE_PATH}/web_black_edit_icon.svg`,
  profile_account_icon: `${BaseUrl}${IMAGE_PATH}/profile_account_icon.svg`,
  profile_setting_icon: `${BaseUrl}${IMAGE_PATH}/profile_setting_icon.svg`,
  profile_help_icon: `${BaseUrl}${IMAGE_PATH}/profile_help_icon.svg`,
  profile_service_icon: `${BaseUrl}${IMAGE_PATH}/profile_service_icon.svg`,
  profile_about_icon: `${BaseUrl}${IMAGE_PATH}/profile_about_icon.svg`,
  login_web_img: `${BaseUrl}${IMAGE_PATH}/login_web_img.svg`,
  help_faq_icon: `${BaseUrl}${IMAGE_PATH}/help_faq_icon.svg`,
  help_share_icon: `${BaseUrl}${IMAGE_PATH}/help_share_icon.svg`,
  help_term_icon: `${BaseUrl}${IMAGE_PATH}/help_share_icon.svg`,
  input_gray_icon: `${BaseUrl}${IMAGE_PATH}/input_gray_icon.svg`,
  no_portfolio_border_img: `${BaseUrl}${IMAGE_PATH}/no_portfolio_border_img.svg`,
  dont_miss_out_one: `${BaseUrl}${IMAGE_PATH}/dont_miss_out_one.svg`,
  dont_miss_out_two: `${BaseUrl}${IMAGE_PATH}/dont_miss_out_two.svg`,
  dont_miss_out_three: `${BaseUrl}${IMAGE_PATH}/dont_miss_out_three.svg`,
  dont_miss_out_four: `${BaseUrl}${IMAGE_PATH}/dont_miss_out_four.svg`,
  dont_miss_out_five: `${BaseUrl}${IMAGE_PATH}/dont_miss_out_five.svg`,
  dont_miss_out_six: `${BaseUrl}${IMAGE_PATH}/dont_miss_out_six.svg`,
  white_logo: `${BaseUrl}${IMAGE_PATH}/white_logo.svg`,
  media_pr_one: `${BaseUrl}${IMAGE_PATH}/media_pr_one.svg`,
  media_pr_two: `${BaseUrl}${IMAGE_PATH}/media_pr_two.svg`,
  black_hoabl_logo: `${BaseUrl}${IMAGE_PATH}/black_hoabl_logo.svg`,
  TimelineWebsite: `${BaseUrl}${IMAGE_PATH}/TimelineWebsite.png`,
  timeline_img_one: `${BaseUrl}${IMAGE_PATH}/timeline_img_one.png`,
  timeline_img_two: `${BaseUrl}${IMAGE_PATH}/timeline_img_two.png`,
  timeline_img_three: `${BaseUrl}${IMAGE_PATH}/timeline_img_three.png`,
  timeline_img_four: `${BaseUrl}${IMAGE_PATH}/timeline_img_four.png`,
  timeline_img_five: `${BaseUrl}${IMAGE_PATH}/timeline_img_five.png`,
  timeline_img_six: `${BaseUrl}${IMAGE_PATH}/timeline_img_six.png`,
  TimelineDesktopWebsite: `${BaseUrl}${IMAGE_PATH}/TimelineDesktopWebsite.png`,
  media_pr_new_img_one: `${BaseUrl}${IMAGE_PATH}/media_pr_new_img_one.png`,
  media_pr_new_img_two: `${BaseUrl}${IMAGE_PATH}/media_pr_new_img_two.png`,
  media_pr_new_img_three: `${BaseUrl}${IMAGE_PATH}/media_pr_new_img_three.png`,
  media_pr_new_img_four: `${BaseUrl}${IMAGE_PATH}/media_pr_new_img_four.png`,
  media_pr_new_img_five: `${BaseUrl}${IMAGE_PATH}/media_pr_new_img_five.png`,
  life_at_hoabl_img_one: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_one.jpg`,
  life_at_hoabl_img_two: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_two.jpg`,
  life_at_hoabl_img_three: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_three.jpg`,
  life_at_hoabl_img_four: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_four.jpg`,
  life_at_hoabl_img_five: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_five.jpg`,
  life_at_hoabl_img_six: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_six.jpg`,
  life_at_hoabl_img_seven: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_seven.jpg`,
  life_at_hoabl_img_eight: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_eight.jpg`,
  life_at_hoabl_img_nine: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_nine.jpg`,
  life_at_hoabl_img_ten: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_ten.jpg`,
  life_at_hoabl_img_eleven: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_eleven.jpg`,
  life_at_hoabl_img_twelve: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_twelve.jpg`,
  life_at_hoabl_img_thirteen: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_thirteen.jpg`,
  life_at_hoabl_img_fourteen: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_img_fourteen.jpg`,
  life_at_hoabl_new_img_one: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_one.jpg`,
  life_at_hoabl_new_img_two: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_two.jpg`,
  life_at_hoabl_new_img_three: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_three.jpg`,
  life_at_hoabl_new_img_four: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_four.jpg`,
  life_at_hoabl_new_img_five: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_five.jpg`,
  life_at_hoabl_new_img_six: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_six.jpg`,
  life_at_hoabl_new_img_seven: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_seven.jpg`,
  life_at_hoabl_new_img_eight: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_eight.jpg`,
  life_at_hoabl_new_img_nine: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_nine.jpg`,
  life_at_hoabl_new_img_ten: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_ten.jpg`,
  life_at_hoabl_new_img_eleven: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_eleven.jpg`,
  life_at_hoabl_new_img_twelve: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_twelve.jpg`,
  life_at_hoabl_new_img_thirteen: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_thirteen.jpg`,
  life_at_hoabl_new_img_fourteen: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_fourteen.jpg`,
  life_at_hoabl_new_img_fifteen: `${BaseUrl}${IMAGE_PATH}/life_at_hoabl_new_img_fifteen.jpg`,
  goa_carousel_img_one: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_one.png`,
  goa_carousel_img_two: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_two.png`,
  goa_carousel_img_three: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_three.png`,
  goa_carousel_img_four: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_four.png`,
  goa_carousel_img_five: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_five.png`,
  goa_carousel_img_six: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_six.png`,
  goa_carousel_img_seven: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_seven.png`,
  goa_carousel_img_eight: `${BaseUrl}${IMAGE_PATH}/goa_carousel_img_eight.png`,
  goa_footer_bg_img: `${BaseUrl}${IMAGE_PATH}/goa_footer_bg_img.png`,
  icon_fb: `${BaseUrl}${IMAGE_PATH}/icon_fb.svg`,
  icon_insta: `${BaseUrl}${IMAGE_PATH}/icon_insta.svg`,
  icon_linkedin: `${BaseUrl}${IMAGE_PATH}/icon_linkedin.svg`,
  icon_youtube: `${BaseUrl}${IMAGE_PATH}/icon_youtube.svg`,
  ayodhya_partnersummit_top_heading_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_top_heading_img.png`,
  ayodhya_partnersummit_logo_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_logo_img.png`,
  ayodhya_partnersummit_heading_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_heading_img.png`,
  ayodhya_partnersummit_middle_logo_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_middle_logo_img.png`,
  ayodhya_partnersummit_para_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_para_img.png`,
  ayodhya_partnersummit_bottom_heading_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_bottom_heading_img.png`,
  ayodhya_partnersummit_bottom_img: `${BaseUrl}${IMAGE_PATH}/ayodhya_partnersummit_bottom_img.png`,
  media_pr_new_img_Six: `${BaseUrl}${IMAGE_PATH}/MC.png`,
  imgOne: `${BaseUrl}${IMAGE_PATH}/goaimgone.png`,
  imgTwo: `${BaseUrl}${IMAGE_PATH}/goaimgtwo.png`,
  imgThree: `${BaseUrl}${IMAGE_PATH}/goaimgthree.png`,
  imgFour: `${BaseUrl}${IMAGE_PATH}/goaimgfour.png`,
  imgFive: `${BaseUrl}${IMAGE_PATH}/goaimgfive.png`,
  WebProfileHeader: `components/web-profile-header/WebProfileHeader`,
  imgSix: `${BaseUrl}${IMAGE_PATH}/imgsix.png`,
  goaMap: `${BaseUrl}${IMAGE_PATH}/goamap.png`,
  goaImgSeven: `${BaseUrl}${IMAGE_PATH}/goaimgseven.jpg`,
  goaImgSevenMob: `${BaseUrl}${IMAGE_PATH}/prelaunch-mob.jpg`,
  hoablLogo: `${BaseUrl}${IMAGE_PATH}/HOABL-logo.png`,
  iconOne: `${BaseUrl}${IMAGE_PATH}/icon_plots.svg`,
  iconTwo: `${BaseUrl}${IMAGE_PATH}/iocn_sq.ft.svg`,
  layerOne: `${BaseUrl}${IMAGE_PATH}/layer1.svg`,
  bar: `${BaseUrl}${IMAGE_PATH}/bar.svg`,
  liquidityIcon: `${BaseUrl}${IMAGE_PATH}/Liquidity.png`,
  SecurityIcon: `${BaseUrl}${IMAGE_PATH}/Security.png`,
  transparencyIcon: `${BaseUrl}${IMAGE_PATH}/Transparency.png`,
  wealthIcon: `${BaseUrl}${IMAGE_PATH}/Wealth.png"`,
  desktopImg: `${BaseUrl}${IMAGE_PATH}/td.jpg`,
  allMobileImg: `${BaseUrl}${IMAGE_PATH}/allmob.jpg`,
  sectionOneDeskImg: `${BaseUrl}${IMAGE_PATH}/ayodhya_desktop_banner.jpg`,
  sectionOneMobImg: `${BaseUrl}${IMAGE_PATH}/ayodhya_mbl_banner.jpg`,

  investdeskImg: `${BaseUrl}${IMAGE_PATH}/indesk.jpg`,
  investMobImg: `${BaseUrl}${IMAGE_PATH}/inmob.jpg`,
  radiusOne: `${BaseUrl}${IMAGE_PATH}/radiusOne.png`,
  radiusTwo: `${BaseUrl}${IMAGE_PATH}/radiusTwo.png`,
  radiusThree: `${BaseUrl}${IMAGE_PATH}/radiusThree.png`,
  applyBtn: `${BaseUrl}${IMAGE_PATH}/applybtn.png`,
  sliderImg: `${BaseUrl}${IMAGE_PATH}/sliderimg.png`,
  modalImage: `${BaseUrl}${IMAGE_PATH}/knowimgdesk-updated.png`,
  picOne: `${BaseUrl}${IMAGE_PATH}/ipicone.png`,
  picTwo: `${BaseUrl}${IMAGE_PATH}/ipictwo.png`,
  picThree: `${BaseUrl}${IMAGE_PATH}/ipicthree.png`,
  picFour: `${BaseUrl}${IMAGE_PATH}/ipicfour.png`,
  picFive: `${BaseUrl}${IMAGE_PATH}/ipicfive.png`,
  picSix: `${BaseUrl}${IMAGE_PATH}/ipicsix.png`,
  picSeven: `${BaseUrl}${IMAGE_PATH}/ipicseven.png`,
  hamBug: `${BaseUrl}${IMAGE_PATH}/hamburger.png`,
  paymentIcon: `${BaseUrl}${IMAGE_PATH}/paymenticon.png`,
  flareIcon: `${BaseUrl}${IMAGE_PATH}/flareicon.png`,

  landscapeView: `${BaseUrl}${IMAGE_PATH}/blog2.png`,
  investBookBtn: `${BaseUrl}${IMAGE_PATH}/investbookbtn.png`,
  investBuyNowBtn: `${BaseUrl}${IMAGE_PATH}/investbuynow.png`,
  readMoreBtn: `${BaseUrl}${IMAGE_PATH}/investreadmorebtn.png`,
  hosIcon: `${BaseUrl}${IMAGE_PATH}/hosicon.png`,
  aeroIcon: `${BaseUrl}${IMAGE_PATH}/aeroicon.png`,
  hotelIcon: `${BaseUrl}${IMAGE_PATH}/hotelicon.png`,
  viewAllProjIcon: `${BaseUrl}${IMAGE_PATH}/viewallproj.png`,
  mapImg: `${BaseUrl}${IMAGE_PATH}/arrow-left.png`,
  mapIcon: `${BaseUrl}${IMAGE_PATH}/mapIcon.png`,
  liquidity_new: `${BaseUrl}${IMAGE_PATH}/liquidity_new.png`,
  security_new: `${BaseUrl}${IMAGE_PATH}/security_new.png`,
  transparency_new: `${BaseUrl}${IMAGE_PATH}/transparency_new.png`,
  wealth_new: `${BaseUrl}${IMAGE_PATH}/wealth_new.png`,
  arrow: `${BaseUrl}${IMAGE_PATH}/arrow.png`,
  TestimonialBanner: `${BaseUrl}${IMAGE_PATH}/testimonial_img.png`,
  menuArrow: `${BaseUrl}${IMAGE_PATH}/arrowmenu.png`,
  loginBtn: `${BaseUrl}${IMAGE_PATH}/loginbtn.png`,
  closeMenuIcon: `${BaseUrl}${IMAGE_PATH}/closemenuIcon.png`,
  arrowIcon: `${BaseUrl}${IMAGE_PATH}/arrow.png`,
  promiseIcon: `${BaseUrl}${IMAGE_PATH}/probgicon.png`,
  shieldIcon: `${BaseUrl}${IMAGE_PATH}/shield.png`,
  ladningPfIconOne: `${BaseUrl}landingpficon1.svg`,
  ladningPfIconTwo: `${BaseUrl}landingpficon2.svg`,
  ladningPfIconThree: `${BaseUrl}landingpticon3.svg`,
  ladningPfIconFour: `${BaseUrl}landingpficon4.svg`,
  founderImg: `${BaseUrl}${IMAGE_PATH}/ceoimg.jpg`,
  indiaMap: `${BaseUrl}${IMAGE_PATH}/indianmapp.jpg`,
  factOne: `${BaseUrl}${IMAGE_PATH}/factone.png`,
  factTwo: `${BaseUrl}${IMAGE_PATH}/facttwo.png`,
  factThree: `${BaseUrl}${IMAGE_PATH}/factthree.png`,
  factFour: `${BaseUrl}${IMAGE_PATH}/keyfact4.jpg`,
  factFive: `${BaseUrl}${IMAGE_PATH}/keyfact5.jpg`,
  factSix: `${BaseUrl}${IMAGE_PATH}/keyfact6.jpg`,
  factSeven: `${BaseUrl}${IMAGE_PATH}/factseven.jpg`,
  factEight: `${BaseUrl}${IMAGE_PATH}/facteight.jpg`,
  brandImgOne: `${BaseUrl}${IMAGE_PATH}/branndimgfour.jpg`,
  brandImgTwo: `${BaseUrl}${IMAGE_PATH}/branndimgthree.jpg`,
  brandImgThree: `${BaseUrl}${IMAGE_PATH}/branndimgtwo.jpg`,
  brandImgFour: `${BaseUrl}${IMAGE_PATH}/branndimgone.jpg`,
  pKarwePic: `${BaseUrl}${IMAGE_PATH}/pkarwepic.jpg`,
  aBalajiPic: `${BaseUrl}${IMAGE_PATH}/ aabala.jpg`,
  yogiPic: `${BaseUrl}${IMAGE_PATH}/yogi.jpg`,
  SamuelPic: `${BaseUrl}${IMAGE_PATH}/samuel.jpg`,
  ashwinder_new: `${BaseUrl}${IMAGE_PATH}/ashwinder_new.jpg`,
  shivprakash_new: `${BaseUrl}${IMAGE_PATH}/shivprakash_new.jpg`,
  vivek_new: `${BaseUrl}${IMAGE_PATH}/vivek_new.jpg`,
  sanjeev_new: `${BaseUrl}${IMAGE_PATH}/sanjeev_new.jpg`,
  pawan_new: `${BaseUrl}${IMAGE_PATH}/pawan_new.jpg`,
  rupali_new: `${BaseUrl}${IMAGE_PATH}/rupali_new.jpg`,
  empty_img: `${BaseUrl}${IMAGE_PATH}/empty_img.png`,
  hetal_new: `${BaseUrl}${IMAGE_PATH}/hetal_new.jpg`,
  deependerImage: `${BaseUrl}${IMAGE_PATH}/deepender.png`,

  exploreImg: `${BaseUrl}${IMAGE_PATH}/explore_icon.svg`,
  imgOne1: `${BaseUrl}${IMAGE_PATH}/imgone.png`,
  imgTwo2: `${BaseUrl}${IMAGE_PATH}/imgtwo.png`,
  imgThree3: `${BaseUrl}${IMAGE_PATH}/imgthree.png`,
  imgFour4: `${BaseUrl}${IMAGE_PATH}/locimg.png`,
  imgFive5: `${BaseUrl}${IMAGE_PATH}/imgfive.png`,
  imgSix6: `${BaseUrl}${IMAGE_PATH}/imgsix.png`,
  imgSeven7: `${BaseUrl}${IMAGE_PATH}/imgseven.png`,
  imgOneInvest: `${BaseUrl}${IMAGE_PATH}/img1.jpg`,
  imgTwoInvest: `${BaseUrl}${IMAGE_PATH}/img2.jpg`,
  imgThreeInvest: `${BaseUrl}${IMAGE_PATH}/img3.jpg`,
  picFourInvest: `${BaseUrl}${IMAGE_PATH}/ipicfour.png`,
  imgFourInvest: `${BaseUrl}${IMAGE_PATH}/img4.jpg`,
  video_01: `${BaseUrl}${VIDEO_PATH}/1.mp4`,
  video_02: `${BaseUrl}${VIDEO_PATH}/2.mp4`,
  mobile_video: `${BaseUrl}${VIDEO_PATH}/Mobile_Video.mp4`,
  web_video: `${BaseUrl}${VIDEO_PATH}/Web_Video.mp4`,
  qrimage: `${BaseUrl}${IMAGE_PATH}/qr.png`,
  scan_qr_header: `${BaseUrl}${IMAGE_PATH}/scanqr-cover.png`,
  qrdetail_background: `${BaseUrl}${IMAGE_PATH}/qr-details-background.png`,
  mobile_background: `${BaseUrl}${IMAGE_PATH}/mobile-background.png`,
  Loader: `${BaseUrl}${IMAGE_PATH}/Loader1.svg`,
  Website_Login_Page_Banners_01: `${BaseUrl}${IMAGE_PATH}/Website_Login_Page_Banners-01.jpg`,
  Website_Login_Page_Banners_02: `${BaseUrl}${IMAGE_PATH}/Website_Login_Page_Banners-02.jpg`,
  Website_Login_Page_Banners_03: `${BaseUrl}${IMAGE_PATH}/Website_Login_Page_Banners-03.jpg`,
  Website_Login_Page_Banners_04: `${BaseUrl}${IMAGE_PATH}/Website_Login_Page_Banners-04.jpg`,
    //Image for Ayodhya Poupup
    ayodhya_popup:`${BaseUrl}${IMAGE_PATH}/ayodhya_popup.webp`
};

//  MediaAssets = {
//   ic_settings,
//   gradient_top,
//   tomorrow_land,
//   devlop_land,
//   arrow_left,
//   ic_blog,
//   instagram,
//   facebook,
//   linkedin,
//   ic_arrow_upward,
//   ic_no_project,
//   ic_appstore,
//   ic_appstore_download,
//   ic_info,
//   ic_insight,
//   ic_logo_white,
//   ic_manage_land,
//   ic_mobile_app,
//   ic_playstore,
//   ic_playstore_download,
//   ic_refer_friend,
//   ic_profile_inactive,
//   ic_investments_inactive,
//   ic_promise_inactive,
//   ic_portfolio_inactive,
//   ic_portfolio_active,
//   ic_profile_active,
//   ic_investments_active,
//   ic_chat,
//   ic_home_active,
//   ic_home_inactive,
//   ic_promise_banner,
//   ic_liquidity,
//   ic_security,
//   ic_wealth,
//   ic_briefcase,
//   ic_preposition_one,
//   ic_preposition_two,
//   ic_preposition_three,
//   ic_update,
//   ic_my_account,
//   ic_security_settings,
//   ic_help_center,
//   ic_facility_management,
//   ic_edit,
//   ic_privacy_policy,
//   ic_faq,
//   ic_about_us,
//   ic_close_white,
//   ic_feedback,
//   ic_profit,
//   ic_siren,
//   ic_promotions,
//   ic_bg_image,
//   ic_home_setting,
//   ic_whatsapp,
//   ic_twitter,
//   ic_gmail,
//   ic_linkedin,
//   ic_no_document,
//   ic_payment_history,
//   ic_right_arrow,
//   ic_chat_icon,
//   ic_chat_refresh,
//   ic_docs,
//   ic_active_bad,
//   ic_active_good,
//   ic_active_ok,
//   ic_active_excellent,
//   ic_active_poor,
//   ic_inactive_bad,
//   ic_inactive_good,
//   ic_inactive_ok,
//   ic_inactive_excellent,
//   ic_inactive_poor,
//   ic_close,
//   ic_upload,
//   ic_remove,
//   ic_profile_placeholder,
//   ic_party_left,
//   ic_party_right,
//   ic_logo_gold,
//   ic_my_services,
//   ic_promise_active,
//   ic_please_wait,
//   ic_ok_success,
//   ic_green_circle,
//   ic_rupee_disabled,
//   ic_rupee_coin,
//   ic_upwards_arrow,
//   ic_download_icon,
//   ic_red_close,
//   ic_green_tick,
//   ic_send_arrow,
//   ic_promise_banner_small,
//   ic_next_arrow,
//   ic_my_service_active,
//   ic_my_service_inactive,
//   ic_source_location,
//   ic_destination_location,
//   ic_promise_card_bg,
//   ic_button_next_arrow,
//   ic_button_next_arrow_black,
//   ic_aboutUs1,
//   ic_aboutUs2,
//   ic_aboutUs3,
//   ic_aboutUs4,
//   ic_group_three_leaves,
//   ic_aboutUs_Detail1,
//   ic_aboutUs_Detail2,
//   ic_aboutUs_Detail3,
//   ic_aboutUs_Detail4,
//   ic_arrow_left,
//   ic_aboutUs_background,
//   ic_hobal_logo,
//   ic_security_shield,
//   ic_next_icon,
//   ic_arrow_back_24px,
//   ic_myAccount_documents,
//   ic_myAccount_payment,
//   ic_arrow_forward,
//   ic_protection_security_tips,
//   my_account,
//   setting,
//   help_center,
//   about_us,
//   my_service,
//   logout_btn,
//   user_profile,
//   camera_icon,
//   home_active_icon,
//   home_deactive_icon,
//   invest_icon_active,
//   invest_icon_deactive,
//   portofolio_active_icon,
//   portofolio_deactive_icon,
//   promises_active_icon,
//   promises_deactive_icon,
//   profile_active_icon,
//   profile_deactive_icon,
//   image_used,
//   edit_camera,
//   edit_profile_icon,
//   edit_remove_icon,
//   edit_deactive_icon,
//   edit_active_icon,
//   tick_dialog_gif,
//   profile_arrow_icon,
//   profile_search_icon,
//   profile_hamburger_icon,
//   investmentDetails_home_img,
//   report_security_btn,
//   ic_invest_firstImage,
//   ic_newLaunch_header_icon,
//   ic_arrow_right,
//   image_used1,
//   image_used2,
//   image_used3,
//   ic_dont_Miss1,
//   ic_dont_Miss2,
//   ic_dont_Miss3,
//   Ic_button_world_icon,
//   home_banner,
//   content_img,
//   hamburger_menu,
//   chevron_up,
//   slider_banner,
//   slider_icon,
//   subtract,
//   globe,
//   testimonial_icon,
//   testimonial_img,
//   menu_icon,
//   explore_icon,
//   explore_icon_gray,
//   search_icon,
//   ongoing_banner,
//   upcoming_banner,
//   landing_page_banner,
//   blog_two,
//   ic_sold_logo,
//   ic_login_Icon_used,
//   ic_newUserWelcome,
//   blog_three,
//   promises_full_card,
//   promises_small_background,
//   beach,
//   temple,
//   ic_location_on,
//   currentInfra,
//   why_to_invest,
//   mumbai_goa,
//   konkan,
//   belapur,
//   ic_card_travel,
//   ic_check_circle,
//   ic_msg,
//   ic_email,
//   ic_call,
//   shopping_basket_NoPortfolio,
//   trending_up_24px,
//   share_goodness,
//   white_arrow_icon,
//   ic_indiaFlag,
//   black_love_icon,
//   black_search_icon,
//   club_house,
//   swimming_house,
//   investment_coming_soon,
//   video_img_one,
//   video_img_two,
//   music_icon,
//   three_d_photo_one,
//   three_d_photo_two,
//   icon_threesixty_view,
//   website_logo,
//   web_msg_icon,
//   web_notification_icon,
//   web_edit_icon,
//   web_black_edit_icon,
//   profile_account_icon,
//   profile_setting_icon,
//   profile_help_icon,
//   profile_service_icon,
//   profile_about_icon,
//   login_web_img,
//   help_faq_icon,
//   help_share_icon,
//   help_term_icon,
//   input_gray_icon,
//   no_portfolio_border_img,
//   dont_miss_out_one,
//   dont_miss_out_two,
//   dont_miss_out_three,
//   dont_miss_out_four,
//   dont_miss_out_five,
//   dont_miss_out_six,
//   white_logo,
//   media_pr_one,
//   media_pr_two,
//   black_hoabl_logo,
//   TimelineWebsite,
//   timeline_img_one,
//   timeline_img_two,
//   timeline_img_three,
//   timeline_img_four,
//   timeline_img_five,
//   timeline_img_six,
//   TimelineDesktopWebsite,
//   media_pr_new_img_one,
//   media_pr_new_img_two,
//   media_pr_new_img_three,
//   media_pr_new_img_four,
//   media_pr_new_img_five,
//   life_at_hoabl_img_one,
//   life_at_hoabl_img_two,
//   life_at_hoabl_img_three,
//   life_at_hoabl_img_four,
//   life_at_hoabl_img_five,
//   life_at_hoabl_img_six,
//   life_at_hoabl_img_seven,
//   life_at_hoabl_img_eight,
//   life_at_hoabl_img_nine,
//   life_at_hoabl_img_ten,
//   life_at_hoabl_img_eleven,
//   life_at_hoabl_img_twelve,
//   life_at_hoabl_img_thirteen,
//   life_at_hoabl_img_fourteen,
//   life_at_hoabl_new_img_one,
//   life_at_hoabl_new_img_two,
//   life_at_hoabl_new_img_three,
//   life_at_hoabl_new_img_four,
//   life_at_hoabl_new_img_five,
//   life_at_hoabl_new_img_six,
//   life_at_hoabl_new_img_seven,
//   life_at_hoabl_new_img_eight,
//   life_at_hoabl_new_img_nine,
//   life_at_hoabl_new_img_ten,
//   life_at_hoabl_new_img_eleven,
//   life_at_hoabl_new_img_twelve,
//   life_at_hoabl_new_img_thirteen,
//   life_at_hoabl_new_img_fourteen,
//   life_at_hoabl_new_img_fifteen,
//   goa_carousel_img_one,
//   goa_carousel_img_two,
//   goa_carousel_img_three,
//   goa_carousel_img_four,
//   goa_carousel_img_five,
//   goa_carousel_img_six,
//   goa_carousel_img_seven,
//   goa_carousel_img_eight,
//   goa_footer_bg_img,
//   icon_fb,
//   icon_insta,
//   icon_linkedin,
//   icon_youtube,
//   ayodhya_partnersummit_top_heading_img,
//   ayodhya_partnersummit_logo_img,
//   ayodhya_partnersummit_heading_img,
//   ayodhya_partnersummit_middle_logo_img,
//   ayodhya_partnersummit_para_img,
//   ayodhya_partnersummit_bottom_heading_img,
//   ayodhya_partnersummit_bottom_img,
//   media_pr_new_img_Six,
// },

export default MediaAssets;
