import React, { useEffect, useState } from "react";
import styles from "./Menu.module.css";
// import menuArrow from "../../assets/images/arrowmenu.png";
// import loginBtn from "../../assets/images/loginbtn.png";
// import closeMenuIcon from "../../assets/images/closemenuIcon.png";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { EditProfileActions } from "redux-container/profile/EditProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import MediaAssets from "assets";
import { Overlay } from "@mantine/core";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";

const menuItems = [
  {
    id: 1,
    menu_name: "Home",
    menu_route: "/",
  },
  {
    id: 2,
    menu_name: "Life at HOABL",
    menu_route: RouteConfigurations.lifeAtHoabl,
  },
  {
    id: 3,
    menu_name: "Media & PR",
    menu_route: RouteConfigurations.mediaPr,
  },
  {
    id: 4,
    menu_name: "Information Center",
    menu_route: "/blog",
  },
  {
    id: 5,
    menu_name: "About Us",
    menu_route: RouteConfigurations.aboutUs,
  },
];

const postLoginMenuItems = [
  {
    id: 1,
    menu_name: "Portfolio",
    menu_route: "/portfolio",
  },
  {
    id: 2,
    menu_name: "Profile",
    menu_route: "/profile",
  },
  {
    id: 3,
    menu_name: "Invest",
    menu_route: "/investments",
  },
  {
    id: 4,
    menu_name: "Life at HOABL",
    menu_route: RouteConfigurations.lifeAtHoabl,
  },
  {
    id: 5,
    menu_name: "Media & PR",
    menu_route: RouteConfigurations.mediaPr,
  },
  {
    id: 6,
    menu_name: "Information Center",
    menu_route: "/blog",
  },
  {
    id: 7,
    menu_name: "chat",
    menu_route: "",
  },
  {
    id: 8,
    menu_name: "Notification",
    menu_route: "/notification",
  },
  {
    id: 9,
    menu_name: "About Us",
    menu_route: "/profile/about-us",
  },
];

const actionDispatch = (dispatch: any) => {
  return {
    logoutRequest: () => dispatch(ProfileAction.logoutRequest()),
    setEditProfileState: (key: any, data: any) =>
      dispatch(EditProfileActions.setEditProfileState(key, data)),
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
    getCountries: () => dispatch(EditProfileActions.getCountriesRequest()),
    getStates: (countryCode: any) =>
      dispatch(EditProfileActions.getStatesRequest(countryCode)),
    getCities: (countryCode: any, stateIsoCode: any) =>
      dispatch(EditProfileActions.getCitiesRequest(countryCode, stateIsoCode)),
  };
};

const Menu = (props: any) => {
  const navigate = useNavigate();

  const {
    logoutRequest,
    setEditProfileState,
    getProfileDetails,
    getCities,
    getStates,
    getCountries,
  } = actionDispatch(useDispatch());

  const {
    profileDetails,
    isEditProfileDialogOpen,
    isProfilePicLoading,
    isLoading,
    states,
    cities,
    countries,
  } = useSelector((state: any) => ({
    profileDetails: state?.profile?.profileDetails,
    isEditProfileDialogOpen: state?.editProfile?.isEditProfileDialogOpen,
    isProfilePicLoading: state?.editProfile?.isProfilePicLoading,
    isLoading: state?.profile?.isLoading,
    cities: state?.editProfile?.cities,
    states: state?.editProfile?.states,
    countries: state?.editProfile?.countries,
  }));

  const [profilePicture, setProfilePicture] = useState("");
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getProfilePicture = () => {
    if (profilePicture === "" || profilePicture === null) {
      if (
        profileDetails?.firstName !== "" &&
        profileDetails?.firstName !== null &&
        profileDetails?.lastName !== "" &&
        profileDetails?.lastName !== null
      ) {
        return `${profileDetails?.firstName
          ?.toUpperCase()
          ?.charAt(0)}${profileDetails?.lastName?.toUpperCase()?.charAt(0)}`;
      } else {
        if (
          profileDetails?.firstName !== null &&
          profileDetails?.firstName.length > 0
        ) {
          return `${profileDetails?.firstName
            ?.toUpperCase()
            ?.charAt(0)}${profileDetails?.firstName?.toLowerCase()?.charAt(1)}`;
        }
      }
    }
  };

  const displayFullName = () => {
    let fullName = "";
    if (profileDetails.firstName !== null && profileDetails.lastName !== null) {
      fullName = `${profileDetails.firstName} ${profileDetails.lastName}`;
    } else {
      fullName = `${profileDetails.firstName}`;
    }
    return fullName;
  };

  const openEditProfile = () => {
    navigate(RouteConfigurations.userDetails);
  };

  useEffect(() => {
    // getCountries();
    setProfilePicture(profileDetails?.profilePictureUrl);
    getProfilePicture();
  }, [
    profileDetails?.profilePictureUrl,
    profilePicture,
    isEditProfileDialogOpen,
  ]);

  useEffect(() => {
    const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);
    if (token) {
      if (!isEditProfileDialogOpen) {
        getProfileDetails();
      }
      setProfilePicture(profileDetails?.profilePictureUrl);
      getProfilePicture();
    }
  }, [isEditProfileDialogOpen]);

  const closeMenu = () => {
    props.setShowMenu(false);
  };
  const redirectToPage = (redirectUrl: string) => {
    navigate(redirectUrl);
  };

  const loginClickHandler = () => {
    navigate(RouteConfigurations.login);
  };

  const userToken = localStorage.getItem("AuthorizationToken");

  const menuPostLoginHandler = (menuLoginUrl: string) => {
    navigate(menuLoginUrl);
  };

  const logout = () => {
    logoutRequest();
    localStorage.clear();
    navigate(RouteConfigurations.landing);
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleLogout = () => {
    setIsLogoutDialogOpen(true);
  };

  return (
    <>
      {userToken ? (
        <div className={styles.menuCon} style={{ height: "100vh" }}>
          <div className={styles.closeMenu}>
            <img
              src={MediaAssets.closeMenuIcon}
              alt="close menu icon"
              onClick={closeMenu}
              width="50"
            />
          </div>
          <div style={{ padding: "0px 15px" }}>
            <div className={styles["user-info-holder"]}>
              <div className={styles["profile-image-container"]}>
                {isProfilePicLoading && !isEditProfileDialogOpen ? (
                  <CircularProgress
                    color="inherit"
                    className={styles["profile-pic-loader"]}
                  />
                ) : profilePicture ? (
                  <img
                    src={profilePicture}
                    className={styles.edit_profile_img_style}
                  />
                ) : (
                  <div className={styles.name_div_style}>
                    <h1>{getProfilePicture()}</h1>
                  </div>
                )}
              </div>
              <div>
                <h2 className={styles.display_full_name}>
                  {displayFullName()}
                </h2>
                <div
                  className={styles["edit-profile-holder"]}
                  onClick={openEditProfile}
                >
                  <span className={styles["edit-profile-link"]}>
                    View Detail
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "-30px" }}>
            {postLoginMenuItems.length > 0 &&
              postLoginMenuItems.map((item) => {
                return (
                  <div style={{ padding: "0px 15px" }}>
                    <div
                      className={styles.post_menu_item}
                      key={item.id}
                      onClick={() => menuPostLoginHandler(item.menu_route)}
                    >
                      {item.menu_name}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={styles["logout-btn-holder"]} onClick={handleLogout}>
            <img src={MediaAssets.logout_btn} />
            <p className={styles["logout-btn"]}>Logout</p>
          </div>
        </div>
      ) : (
        <div className={styles.menuCon}>
          <div className={styles.closeMenu}>
            <img
              src={MediaAssets.closeMenuIcon}
              alt="close menu icon"
              onClick={closeMenu}
              width="50"
            />
          </div>
          <div className={styles.menuWrapper}>
            {menuItems.length > 0 &&
              menuItems.map((item: any, index: any) => {
                return (
                  <div
                    className={styles.menuItem}
                    key={index}
                    onClick={() => {
                      redirectToPage(item?.menu_route);
                    }}
                  >
                    <p>{item?.menu_name}</p>
                    <img
                      src={MediaAssets.menuArrow}
                      alt="menu arrow"
                      // onClick={() => {
                      //   redirectToPage(item?.menu_route);
                      // }}
                      width={35}
                    />
                  </div>
                );
              })}
          </div>
          {/* <div className={styles.loginBtn} onClick={loginClickHandler}>
            <img src={MediaAssets.loginBtn} alt="login btn" />
          </div> */}
        </div>
      )}

      {isLogoutDialogOpen && (
        <Overlay
          onClick={handleCloseLogoutDialog}
          opacity={0.6}
          color="#000"
          blur={5}
        />
      )}

      <ConfirmationDialog
        shouldOpen={isLogoutDialogOpen}
        content={<strong>Are you sure you want to logout?</strong>}
        okText="Yes"
        cancelBtnText="No"
        cancelHandler={handleCloseLogoutDialog}
        okHandler={logout}
        showContainedDialog={!isSmallerScreen}
        hideBackdrop={true}
      />
    </>
  );
};

export default Menu;
