import { Constants } from "constants/Constants";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getMediaAndPrsSuccess: ["response"],
  getMediaAndPrsFailure: ["error"],
  getMediaAndPrsRequest: ["params"],

  setMediaAndPrsState: ["key", "value"],
});

export const MediaAndPrsTypes = Types;

export const MediaAndPrsActions = Creators;

export const INITIAL_STATE = {
  MediaAndPrList: [],
  error: null,
  isLoading: false,
  isSuccess: false,
  message: null,
  totalCount: 0,
  page: 1,
  rowsPerPage: 20,
};

/* Get MediaAndPrs */
export const getMediaAndPrsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMediaAndPrsSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      MediaAndPrList: response.data,
      isLoading: false,
      totalCount: response.totalCount,
    };
  }
};

export const getMediaAndPrsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    // error: action.error.message,
  };
};

export const setMediaAndPrsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MEDIA_AND_PRS_SUCCESS]: getMediaAndPrsSuccess,
  [Types.GET_MEDIA_AND_PRS_FAILURE]: getMediaAndPrsFailure,
  [Types.GET_MEDIA_AND_PRS_REQUEST]: getMediaAndPrsRequest,

  [Types.SET_MEDIA_AND_PRS_STATE]: setMediaAndPrsState,
});
