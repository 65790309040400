import React from "react";
import clevertap from "clevertap-web-sdk";

const useClevertap = () => {
  // TO PUSH EVENT TO CLEVERTAP
  const clevertapPushEvent = (
    eventName: string,
    eventData: { [key: string]: any }
  ) => {
    console.log(eventData,'some event data')
    clevertap.event.push(eventName, eventData)
  };

  // TO LOGIN USER TO CLEVERTAP
  const clevertapLoginUser = (userData: { [key: string]: any }) => {
    clevertap.onUserLogin.push({
      Site: userData,
    });
  };

  // UPDATE USER OF CLEVERTAP
  const clevertapUpdateUser = (userData: { [key: string]: any }) => {
    clevertap.profile.push({
      Site: userData,
    });
  };

  return { clevertapPushEvent, clevertapLoginUser, clevertapUpdateUser };
};

export default useClevertap;

