import MediaAssets from "assets";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPreLoginPageAction } from "redux-container/pre-login-page-management-redux/PreLoginPageManagementRedux";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import { RouteConfigurations } from "routes/RouteConfigurations";
import YouTubeIcon from "@mui/icons-material/YouTube";
import styles from "./LandingFooter.module.css";

const actionDispatch = (dispatch: any) => {
  return {
    getPreLoginPageDetails: (params: any) =>
      dispatch(getPreLoginPageAction.getPreLoginPagesRequest(params)),
    getProjectContentList: (params: any) =>
      dispatch(getPreLoginPageAction.getProjectContentsRequest(params)),
    setPagemanagementState: (key: any, value: any) =>
      dispatch(getPreLoginPageAction.setPageState(key, value)),
    getAllInvestments: () =>
      dispatch(NewLaunchActions.getAllInvestmentsRequest()),
  };
};
const LandingFooter = () => {
  const navigate = useNavigate();

  const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);

  const projectDetails = useSelector((state: any) => {
    return state?.newLaunch?.investments;
  });

  const handleRedirect = (data: any) => {
    navigate(`/investment-details/${data.launchName.replace(/%20|\s/g, "-")}`, {
      state: {
        projectId: data.id,
        area: data?.areaStartingFrom,
        cost: data.priceStartingFrom,
        title: data.launchName,
        location: data.address.city,
        description: data.shortDescription,
        isDetailed: data.isSoldOut,
        isExplore: data.isSoldOut,
        isSold: data.isSoldOut,
        dataAsObject: data,
        // medias: medias[index],
      },
    });
  };

  const handleSocialMediaClick = (url: any) => {
    window.open(url, "_blank");
  };

  const mobileleSocialLinkHandler = (url: any) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {/* desktop design start */}
      <div className={styles.desktopCon}>
        <div className={styles.firstRow}>
          <div
            className={styles.logo}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={MediaAssets.content_img} />
          </div>
          <div className={styles.InvestWithUs}>
            <h1 className={styles.heading} style={{ marginBottom: "25px" }}>
              Invest with us
            </h1>
            <ul>
              {/* <li
                className={styles.landingProject}
                onClick={() => {
                  navigate("/ayodhya");
                }}
              >
                Ayodhya
              </li> */}
              {projectDetails?.map((data: any, index: number) => {
                return (
                  <>
                    {data?.status == "1001" && (
                      <li
                        key={index}
                        className={styles.landingProject}
                        onClick={() => {
                          handleRedirect(data);
                        }}
                      >
                        {data?.enquiryTitle ? data.enquiryTitle : data?.launchName}
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
          <div className={styles.Other}>
            <h1 className={styles.heading}>Others</h1>
            <ul style={{ marginTop: "25px" }}>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate("/profile/about-us");
                }}
              >
                About us
              </li>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate("/promise");
                }}
              >
                Our Promises
              </li>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate(RouteConfigurations.mediaPr);
                }}
              >
                Media & PR
              </li>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate(RouteConfigurations.lifeAtHoabl);
                }}
              >
                Life At Hoabl
              </li>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate(RouteConfigurations.scanQR);
                }}
              >
                RERA Codes
              </li>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate(RouteConfigurations.TAC);
                }}
              >
                Grievance Redressal
              </li>
              <li
                className={styles.landingProject}
                onClick={() => {
                  navigate(RouteConfigurations.TAC);
                }}
              >
                Terms and Conditions
              </li>
            </ul>
          </div>
          <div className={styles.contactUs}>
            <h1 className={styles.heading}>Contact Us</h1>
            <div className={styles.contactDetais}>
              <ul
                style={{
                  fontSize: "16px",
                  marginTop: "-7px",
                  fontFamily: "Futura Md BT",
                }}
              >
                <li
                  style={{
                    fontSize: "16px",
                    marginTop: "-15px",
                    fontFamily: "Futura Md BT",
                  }}
                  className={styles.landingProject}
                >
                  Corporate office:-
                </li>
              </ul>
                <div
                  style={{
                    fontSize: "16px",
                  marginTop: "5px",
                    fontFamily: "Futura Md BT",
                  }}
                  className={styles.officeAddress}
                >
                  The House of Abhinandan Lodha Estate Holdings Private Limited,
                701 Unit, 7th Floor, 1 Aerocity Building, NIBR Compound, Mohili
                Village, Sakinaka, Safed Pool, Mumbai – 400072
                </div>
            </div>
          </div>
        </div>
        <div>
        <div className={styles.secondRow}>
          {/* {token && (
            <div onClick={() => navigate("/profile/help-center")}>
              <ul className={styles.secondRowUl}>
                <li style={{ listStyle: "none" }}>Privacy Policy</li>
                <li style={{ listStyle: "none" }}>Terms and conditiions</li>
              </ul>
            </div>
          )} */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ color: "#ffffff" }}
              className={styles.socialMediaHeading}
            >
              HoABL on Social
            </div>
            <div className={styles.socialIcons}>
              <img
                src={MediaAssets.instagram}
                style={{ width: "25px", marginLeft: "20px" }}
                onClick={() =>
                  handleSocialMediaClick(
                    "https://instagram.com/thehouseofabhinandanlodha?igshid=MmIzYWVlNDQ5Yg=="
                  )
                }
              />
              <img
                src={MediaAssets.facebook}
                style={{ width: "25px", marginLeft: "20px" }}
                onClick={() =>
                  handleSocialMediaClick(
                    "https://www.facebook.com/HoABLOfficial"
                  )
                }
              />
              <img
                src={MediaAssets.linkedin}
                style={{ width: "25px", marginLeft: "20px" }}
                onClick={() =>
                  handleSocialMediaClick(
                    "https://www.linkedin.com/company/hoabl/"
                  )
                }
              />
              <YouTubeIcon
                style={{
                  width: "25px",
                  marginLeft: "20px",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                className={styles.youtubeIcon}
                onClick={() =>
                  handleSocialMediaClick(
                    "https://www.youtube.com/@TheHouseofAbhinandanLodha"
                  )
                }
              />
            </div>
          </div>
          <div className={styles.companyFooter}>
            <p style={{ color: "#ffffff", marginTop: "1rem" }}>
              HOUSE OF ABHINANDAN LODHA ESTATE HOLDINGS PRIVATE LIMITED
            </p>
            <p className={styles.disclaimerTextDesk}>
              The House of Abhinandan Lodha® is an independent real estate
              consumer-tech brand. We have no association with Macrotech
              Developers Limited or any of its subsidiaries, affiliates or
              associate companies.
            </p>
          </div>
        </div>
         <p className={styles.disclaimerTextTab}>
                The House of Abhinandan Lodha® is an independent real estate
                consumer-tech brand. We have no association with Macrotech
                Developers Limited or any of its subsidiaries, affiliates or
                associate companies.
         </p>
         </div>
      </div>

      {/* Desktop Design End   */}

      {/* Footer */}
      <div className={styles.container}>
        <div className={styles.bcColor}>
          <div
            className={styles.logo}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={MediaAssets.content_img} />
          </div>
          <div className={styles.midContent}>
            <div className={styles.footerInvest}>
              <h1 className={styles.heading} style={{ marginBottom: "25px" }}>
                Invest with us
              </h1>
              <ul>
                {/* <li
                  className={styles.listItem}
                  onClick={() => {
                    navigate(RouteConfigurations.ayodhya);
                  }}
                >
                  Ayodhya
                </li> */}
                {projectDetails?.map((data: any, index: number) => {
                  return (
                    <>
                      {data?.status == "1001" && (
                        <li
                          key={index}
                          onClick={() => {
                            handleRedirect(data);
                          }}
                          style={{
                            position: "relative",
                          }}
                          className={styles.listItem}
                        >
                          {data?.enquiryTitle
                            ? data.enquiryTitle
                            : data?.launchName}
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
            <div className={styles.Other}>
              <h1 className={styles.heading}>Others</h1>
              <ul>
                <li
                  onClick={() => {
                    navigate("/profile/about-us");
                  }}
                >
                  About us
                </li>
                <li
                  onClick={() => {
                    navigate("/promise");
                  }}
                >
                  Our Promises
                </li>
                <li
                  onClick={() => {
                    navigate(RouteConfigurations.mediaPr);
                  }}
                >
                  Media & PR
                </li>
                <li
                  onClick={() => {
                    navigate(RouteConfigurations.lifeAtHoabl);
                  }}
                >
                  Life At Hoabl
                </li>
                <li
                  onClick={() => {
                    navigate(RouteConfigurations.scanQR);
                  }}
                >
                  RERA Codes
                </li>
                <li
                  onClick={() => {
                    navigate(RouteConfigurations.TAC);
                  }}
                >
                  Grievance Redressal
                </li>
                <li
                  onClick={() => {
                    navigate(RouteConfigurations.TAC);
                  }}
                >
                  Terms and Conditions
                </li>
              </ul>
            </div>
            <div className={styles.contectUsCon}>
              <div className={styles.contactUsText}>Contact us</div>
              <li
                style={{
                  position: "relative",
                  top: "30px",
                  fontSize: "18px",
                }}
                className={styles.footerCorporate}
              >
                CORPORATE OFFICE:-
              </li>
              <div
                // style={{ fontSize: "12px", marginTop: "5px" }}
                className={styles.addressText}
                style={{
                  fontSize: "16px",
                  fontFamily: "Futura Md BT",
                }}
              >
                The House of Abhinandan Lodha Estate Holdings Private Limited,
                701 Unit, 7th Floor, 1 Aerocity Building, NIBR Compound, Mohili
                Village, Sakinaka, Safed Pool, Mumbai – 400072
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              marginLeft: "2%",
            }}
          >
            <div style={{ color: "#ffffff", marginTop: "15px" }}>
              HoABL on Social
            </div>

            <div className={styles.socialMediaIcons}>
              <img
                src={MediaAssets.instagram}
                style={{ width: "25px", marginLeft: "20px" }}
                onClick={() =>
                  mobileleSocialLinkHandler(
                    "https://instagram.com/thehouseofabhinandanlodha?igshid=MmIzYWVlNDQ5Yg=="
                  )
                }
              />
              <img
                src={MediaAssets.facebook}
                style={{ width: "25px", marginLeft: "20px" }}
                onClick={() =>
                  mobileleSocialLinkHandler(
                    "https://www.facebook.com/HoABLOfficial"
                  )
                }
              />
              <img
                src={MediaAssets.linkedin}
                style={{ width: "25px", marginLeft: "20px" }}
                onClick={() =>
                  mobileleSocialLinkHandler(
                    "https://www.linkedin.com/company/hoabl/"
                  )
                }
              />
              <YouTubeIcon
                style={{
                  width: "25px",
                  marginLeft: "20px",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() =>
                  mobileleSocialLinkHandler(
                    "https://www.youtube.com/@TheHouseofAbhinandanLodha"
                  )
                }
              />
            </div>
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <p style={{ color: "#ffffff" }}>
              HOUSE OF ABHINANDAN LODHA ESTATE HOLDINGS PRIVATE LIMITED
            </p>
            <p style={{ color: "#ffffff", marginTop: "1rem" }}>
              The House of Abhinandan Lodha® is an independent real estate
              consumer-tech brand. We have no association with Macrotech
              Developers Limited or any of its subsidiaries, affiliates or
              associate companies.
            </p>
          </div>
          <div className={styles.footerEnd}>
            <div className={styles.copyright}>&#169; Copyright 2023</div>
            <div className={styles.line}>|</div>
            <div className={styles.name}>The House of Abhinandan Lodha</div>
          </div>
          <div className={styles.footerRights}>All rights reserved</div>
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
