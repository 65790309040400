import { createActions, createReducer } from "reduxsauce";
// import { setNewLaunchState } from "../new-launch/NewLaunchRedux";

const { Types, Creators } = createActions({
  getLandSkuListSuccess: ["response"],
  getLandSkuListRequest: ["data"],
  getLandSkuListFailure: ["error"],

  postVideoCallSuccess: ["response"],
  postVideoCallRequest: ["data"],
  postVideoCallFailure: ["error"],

  postAddInventorySuccess: ["response"],
  postAddInventoryRequest: ["data"],
  postAddInventoryFailure: ["error"],

  setLandSkuListState: ["key", "value"],
});

export const LandSkuListTypes = Types;

export const LandSkuListActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  landSkuListData: null,
  isSuccess: false,
  message: null,
  isResponseDialogOpen: false,
  isDialogOpen: false,
  isApplySkuOpen: false,
  isInventoryAdded: false,
  projectId: 0,
  launchName: "",
};

export const getLandSkuListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getLandSkuListSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      landSkuListData: response.data,
      isLoading: false,
    };
  }
};

export const getLandSkuListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const postVideoCallRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    isSuccess: false,
  };
};

export const postVideoCallSuccess = (state: any, action: any) => {
  const response = action.response?.data;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      message: response.message
        ? response.message
        : "Video Call request sent successfully.",
      isSuccess: true,
    };
  }
};

export const postVideoCallFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action?.error?.message,
    isSuccess: false,
    message: action?.error?.message,
  };
};

export const postAddInventoryRequest = (state: any, action: any) => {
  return {
    ...state,
    isLoading: true,
    isResponseDialogOpen: false,
    isSuccess: false,
  };
};

export const postAddInventorySuccess = (state: any, action: any) => {
  const response = action?.response;
  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      message: response.message ? response.message : "",
      isSuccess: true,
      isResponseDialogOpen: true,
      isInventoryAdded: true,
    };
  }
};

export const postAddInventoryFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action?.error?.message,
    isSuccess: false,
    message: action?.error?.message,
    isResponseDialogOpen: true,
  };
};

export const setLandSkuListState = (state: any, action: any) => {
  const newLandSkuListState = {
    ...state,
  };
  newLandSkuListState[action.key] = action.value;
  return newLandSkuListState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LAND_SKU_LIST_REQUEST]: getLandSkuListRequest,
  [Types.GET_LAND_SKU_LIST_SUCCESS]: getLandSkuListSuccess,
  [Types.GET_LAND_SKU_LIST_FAILURE]: getLandSkuListFailure,

  [Types.POST_VIDEO_CALL_REQUEST]: postVideoCallRequest,
  [Types.POST_VIDEO_CALL_SUCCESS]: postVideoCallSuccess,
  [Types.POST_VIDEO_CALL_FAILURE]: postVideoCallFailure,

  [Types.POST_ADD_INVENTORY_REQUEST]: postAddInventoryRequest,
  [Types.POST_ADD_INVENTORY_SUCCESS]: postAddInventorySuccess,
  [Types.POST_ADD_INVENTORY_FAILURE]: postAddInventoryFailure,

  [Types.SET_LAND_SKU_LIST_STATE]: setLandSkuListState,
});
