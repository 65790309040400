import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPreLoginPagesSuccess: ["response"],
  getPreLoginPagesFailure: ["error"],
  getPreLoginPagesRequest: ["data"],

  getPreLoginPromiseSuccess: ["response"],
  getPreLoginPromiseFailure: ["error"],
  getPreLoginPromiseRequest: ["data"],

  setPreLoginPageState: ["key", "value"],
});

export const getPreLoginPageTypes = Types;

export const getPreLoginPageAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  pageData: null,
  promiseDetails: null,
  promiseData: null,
  investmentDetails: null,
  isSuccess: false,
  isDialogOpen: false,
  profilePageData: {},
};

export const getPreLoginPagesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPreLoginPagesSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response.data.pageManagementsOrCollectionTwoModels) {
    return {
      ...state,
      error: null,
      investmentDetails: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  if (!response.data.pageManagementsOrCollectionTwoModels) {
    return {
      ...state,
      error: null,
      pageData: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPreLoginPagesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};
export const getPreLoginPromiseRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPreLoginPromiseSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      promiseData: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getPreLoginPromiseFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setPreLoginPageState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRE_LOGIN_PAGES_SUCCESS]: getPreLoginPagesSuccess,
  [Types.GET_PRE_LOGIN_PAGES_FAILURE]: getPreLoginPagesFailure,
  [Types.GET_PRE_LOGIN_PAGES_REQUEST]: getPreLoginPagesRequest,

  [Types.GET_PRE_LOGIN_PROMISE_SUCCESS]: getPreLoginPromiseSuccess,
  [Types.GET_PRE_LOGIN_PROMISE_FAILURE]: getPreLoginPromiseFailure,
  [Types.GET_PRE_LOGIN_PROMISE_REQUEST]: getPreLoginPromiseRequest,

  [Types.SET_PRE_LOGIN_PAGE_STATE]: setPreLoginPageState,
});
