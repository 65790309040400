import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { CommingSoonProjectAction } from "redux-container/comming-soon/CommingSoon";

export function* getCommingSoonProject(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.fetchCommingSoonProjects, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        CommingSoonProjectAction.getCommingSoonProjectSuccess(response.data)
      );
    } else {
      yield put(
        CommingSoonProjectAction.getCommingSoonProjectFailure(response.data)
      );
    }
  } catch (error) {
    yield put(CommingSoonProjectAction.getCommingSoonProjectFailure(error));
  }
}
