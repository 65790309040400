import { Box, Divider } from "@mui/material";
// import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { ReactNode } from "react";
import styles from "./ConfirmationDialog.module.css";

interface IConfirmationDialogProps {
  shouldOpen: boolean;
  title?: string;
  content: any;
  okText?: string;
  cancelHandler: (e?: any) => any;
  okHandler?: (e?: any) => any;
  cancelBtnText?: string;
  isOkDisabled?: boolean;
  detailMessageContent?: ReactNode;
  showContainedDialog?: boolean;
  hideBackdrop?: boolean;
}

const ConfirmationDialog = ({
  shouldOpen,
  content,
  okText,
  cancelHandler,
  okHandler,
  cancelBtnText = "Cancel",
  isOkDisabled,
  detailMessageContent,
  showContainedDialog,
  hideBackdrop = false,
}: IConfirmationDialogProps) => {
  const dialogContent = content ? content : "";
  const okBtnText = okText ? okText : "Okay";

  const handleClose = () => {
    cancelHandler();
  };

  const handleOkay = () => {
    okHandler ? okHandler() : cancelHandler();
  };

  return (
    <Dialog
      hideBackdrop={hideBackdrop}
      PaperProps={
        showContainedDialog
          ? {
              style: {
                borderRadius: "15px",
              },
            }
          : {}
      }
      maxWidth={"md"}
      open={shouldOpen}
      disableEscapeKeyDown={true}
      onClose={handleClose}
    >
      <DialogContent
        className={showContainedDialog ? styles["contained-dialog"] : ""}
      >
        <DialogContentText className={styles["dialog-Content"]}>
          {dialogContent}
        </DialogContentText>
        {detailMessageContent}
      </DialogContent>
      {/* <Divider
        className={showContainedDialog ? styles["contained-dialog-margin"] : ""}
      /> */}
      <DialogActions className={styles["confirm-dialog-actions"]}>
        <MuiStyledButton
          variant="outlined"
          size="large"
          style={{
            width: "50%",
            fontSize: "0.8rem",
            border: "1px solid black ",
          }}
          sx={{
            fontFamily: "Futura Md BT",
            color: "black",
            borderRadius: "1rem",
          }}
          onClick={handleClose}
        >
          {cancelBtnText}
        </MuiStyledButton>

        <MuiStyledButton
          variant="contained"
          size="large"
          style={{
            width: "50%",
            fontSize: "0.8rem",
          }}
          sx={{
            fontFamily: "Futura Md BT",
            borderRadius: "1rem",
            color: "white",
            background: "black",
          }}
          onClick={handleOkay}
        >
          {okBtnText}
        </MuiStyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
