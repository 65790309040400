import React, { useState, useEffect } from "react";
import styles from "./DesktopPromise.module.css";
import { useSelector } from "react-redux";
// import shieldIcon from "../../../assets/images/shield.png";
// import exploreImg from "../../../assets/images/explore_icon.svg";
import WebProfileHeader from "components/web-profile-header/WebProfileHeader";
import CommonHeader from "components/pre-login-header/CommonHeader";
import PromisesDetailWrapper from "components/promisesDetailWrapper/promisesDetailWrapper";
import MediaAssets from "assets";
// import liquidity_new from "../../../assets/images/liquidity_new.png";
// import security_new from "../../../assets/images/security_new.png";
// import transparency_new from "../../../assets/images/transparency_new.png";
// import wealth_new from "../../../assets/images/wealth_new.png";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DesktopPromise = () => {
  const { pathname } = useLocation();
  const [showPromiseModal, setShowPromiseModal] = useState(false);
  const icons = [
    MediaAssets.security_new,
    MediaAssets.liquidity_new,
    MediaAssets.transparency_new,
    MediaAssets.wealth_new,
  ];
  const [promiseData, setPromiseData] = useState({});
  const promises = useSelector((state: any) => {
    return state?.preLoginPageManagement?.pageData?.homePagesOrPromises;
  });
  const openPromiseDetails = (promiseDetails: any) => {
    setShowPromiseModal(true);
    setPromiseData(promiseDetails);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  console.log("promises", promises);

  return (
    <>
      {showPromiseModal && (
        <PromisesDetailWrapper
          promiseDetails={promiseData}
          setShowPromiseModal={setShowPromiseModal}
        />
      )}
      <div className={styles.desktopMenu}>
        <WebProfileHeader
          backgroundColor="#000000"
          middleDetails={true}
          contentColor="#FFFFFF"
          inputDivBackgroundColor="transparent"
          inputDivBorder="1px solid #727272"
          inputBackGroundColor="transparent"
          inputBorder="none"
          inputBoxShadow="none"
          inputOutline="none"
          inputImg={MediaAssets.input_gray_icon}
          websiteLogo={MediaAssets.white_logo}
          inputPlaceHolderColor="#727272"
          stickyBackgroundColor="#000000"
        />
      </div>

      <div className={styles.container}>
        <div className={styles.logoWithHeader}>
          <img src={MediaAssets.exploreImg} alt="menuimg" />
          <div className={styles["title"]}>our promises</div>
        </div>
        <div className={styles.wrapper}>
          {promises &&
            promises.map((promise: any, index: number) => {
              return (
                <>
                  <div
                    className={styles.openMenu}
                    onClick={() => {
                      openPromiseDetails(promise);
                    }}
                  >
                    <div className={styles.inside}>
                      <div className={styles.menu}>
                        <div className={styles.shieldWithTitle}>
                          <LazyLoadImage
                            src={icons[index]}
                            alt="Landing page"
                            className={styles[`shield${index}`]}
                            style={{
                              marginLeft:
                                index === 2
                                  ? "30px"
                                  : index === 1
                                  ? "-10px"
                                  : index === 3
                                  ? "-8px"
                                  : "0px",
                              width: "100px",
                              lineHeight: 0,
                              display: "flex",
                            }}
                            id={`shield${index}`}
                          />
                          <p
                            className={`text${index}`}
                            style={{
                              marginLeft:
                                index === 1
                                  ? "-12px"
                                  : index === 3
                                  ? "-8px"
                                  : "",
                              fontSize: "20px",
                            }}
                          >
                            {promise.name}
                          </p>
                        </div>

                        <div
                          className={styles.descp}
                          style={{ fontSize: "16px" }}
                        >
                          {promise.shortDescription}
                        </div>
                        {/* <div className={styles.arrowWrapper}>
                    <img
                      src={arrow}
                      alt="arrowIcon"
                      width={24}
                      onClick={() => {
                        toogleClassOne(index);
                      }}
                      className={`arrow${index}`}
                    />
                  </div> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default DesktopPromise;
