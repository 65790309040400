import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";

import { PageSeoAction } from "redux-container/page-seo/PageSeo";
export function* getPageSeo(api: any, action: any): SagaIterator {
 
  try {
    const response = yield call(api.fetchPageSeoData, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PageSeoAction.getPageSeoDataSuccess(response.data));
    } else {
      yield put(PageSeoAction.getPageSeoDataFailure(response.data));
    }
  } catch (error) {
    yield put(PageSeoAction.getPageSeoDataFailure(error));
  }
}
