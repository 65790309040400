export const Constants = {
  DEVELOPMENT_BASE_URL: "https://cms-api-dev.hoabl.in/",

  // DEVELOPMENT_BASE_URL: "http://192.168.1.15:8002/",

  // LOCAL_BASE_URL: "https://cms-api.hoabl.in/",

  LOCAL_BASE_URL: "http://192.168.1.17:8002/",

  QA_BASE_URL: "https://cms-api-stg.hoabl.in/",

  PREPRODUCTION_BASE_URL: "https://cms-api-uat.hoabl.in/",

  PRODUCTION_BASE_URL: "https://cms-api.hoabl.in/",

  GOOGLE_MAP_KEY: "AIzaSyBAQrIpZqIE4moMhptpOYD9Wa9585l9ju0",
  IMAGE_BASE_URL: "https://hoabl-antino-share.s3.ap-south-1.amazonaws.com",
  IMAGE_PATH: "/assets/images",

  AUTHORIZATION_TOKEN: "AuthorizationToken",
  LOGGED_IN_USER: "LoggedInUser",
  FIRSTNAME: "Firstname",
  LASTNAME: "Lastname",

  ERROR_COLOR: "#f45353",

  MAX_MOBILE_NUMBER_DIGITS: 10,
  MAX_OTP_DIGITS: 6,
  SNACKBAR_DURATION: 5000,

  MAX_RESEND_OTP_TIMER: 120, // 2 minutes

  HOABL_WEB_LINK: "https://hoabl.in/",

  HOABL_HELP_CENTER_MAIL: "care@hoabl.in",

  GOOGLE_PLAY_STORE_URL:
    "https://play.google.com/store/apps/details?id=com.hoabl",
  APPLE_APP_STORE_URL:
    "https://apps.apple.com/in/app/the-house-of-abhinandan-lodha/id1630999647",

  FIRST_MSG:
    "You have incorrectly typed the OTP 1 time. Click on Resend OTP to receive OTP again",
  SECOND_MSG:
    "You have incorrectly typed the OTP 2 times. Click on Resend OTP to receive OTP again",
  THIRD_MSG:
    "You have incorrectly typed the OTP 3 times. Click on Resend OTP to receive OTP again",
  FOURTH_MSG:
    "You have incorrectly typed the OTP 4 times. Click on Resend OTP to receive OTP again",
  FIFTH_MSG:
    "You have incorrectly typed the OTP 5 times. Click on Resend OTP to receive OTP again",

  ASSETS_BASE_URL: "https://d314mba9smb17e.cloudfront.net",
  ASSETS_IMAGE_PATH: "/images",
  ASSETS_VIDEO_PATH: "/videos",


  //CLEVERTAP
  DEVELOPMENT_CLEVERTAP_CLIENT_ID : "TEST-9W4-KZZ-4Z7Z",
  PRODUCTION_CLEVERTAP_CLIENT_ID : "8W4-KZZ-4Z7Z",
  DEVELOPMENT_CLEVERTAP_CLIENT_TOKEN : "",
  PRODUCTION_CLEVERTAP_CLIENT_TOKEN : "113-40b",
};
