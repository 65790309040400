import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Avatar, Button, ButtonProps } from "@mui/material";
import MediaAssets from "assets";

const EastArrow = () => (
  <Avatar
    src={MediaAssets.ic_button_next_arrow}
    sx={{ width: 20, height: 20 }}
  />
);

const MuiLoadingButton = (props: LoadingButtonProps) => {
  const { children } = props;
  return (
    <LoadingButton  {...props}>
      {children}
    </LoadingButton>
  );
};
const MuiStyledButton = (props: ButtonProps) => {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
};

const ButtonArrowIcon = (props: { [key: string]: any }) => {
  const { fillcolor = "#2F2A1F" } = props;
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 35 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m25.793 1.136.169.14 8.186 8.184-8.186 8.184a1.22 1.22 0 0 1-1.867-1.557l.14-.169 5.24-5.239-27.42.001a1.22 1.22 0 0 1-.124-2.427l.22-.01 27.324-.003-5.24-5.238a1.22 1.22 0 0 1-.14-1.556l.14-.17a1.221 1.221 0 0 1 1.558-.14z"
        fill={fillcolor}
        fill-rule="nonzero"
      />
    </svg>
  );
};

export { MuiLoadingButton, MuiStyledButton, ButtonArrowIcon };
