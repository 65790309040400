import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPageSeoDataSuccess: ["response"],
  getPageSeoDataFailure: ["error"],
  getPageSeoDataRequest: ["data"],
});

export const PageSeoType = Types;

export const PageSeoAction = Creators;

export const INITIAL_STATE = {
  seoData: [],
  error: null,
  isLoading: "",
};

export const getPageSeoDataRequest = (state: any) => {


  return {
    ...state,
    isLoading: true,
  };
};

export const getPageSeoDataSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response.data) {
    return {
      ...state,
      seoData: response.data,
      error: null,
      isLoading: false,
    };
  }
};

export const getPageSeoDataFailure = (state: any, action: any) => {
  return {
    ...state,
    error: action.error.message,
    isLoading: false,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGE_SEO_DATA_SUCCESS]: getPageSeoDataSuccess,
  [Types.GET_PAGE_SEO_DATA_FAILURE]: getPageSeoDataFailure,
  [Types.GET_PAGE_SEO_DATA_REQUEST]: getPageSeoDataRequest,
});
