import { useState } from "react";
import styles from "./promisesDetailWrapper.module.css";
import CloseIcon from "@mui/icons-material/Close";
import MediaAssets from "assets";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
// import liquidityIcon from "../../assets/images/Liquidity.png";
// import SecurityIcon from "../../assets/images/Security.png";
// import transparencyIcon from "../../assets/images/Transparency.png";
// import wealthIcon from "../../assets/images/Wealth.png";

import Modal from "@mui/material/Modal";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
// interface PromisesDetailWrapperProps {
//   heading: string;
//   name: string;
// }

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "100% !important",
  overflow: "scroll",
  p: 4,
};

const PromisesDetailWrapper = (props: any) => {
  const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);
  const handleClose = () => {
    props.setShowPromiseModal(false);
  };

  const icons = [
    MediaAssets.SecurityIcon,
    MediaAssets.transparencyIcon,
    MediaAssets.liquidityIcon,
    MediaAssets.wealthIcon,
  ];

  const navigate = useNavigate();
  const portfolioHandler = () => {
    // if (token) {
    //   return navigate(RouteConfigurations.portfolio);
    // }
    navigate(RouteConfigurations.investments);
  };

  return (
    <>
      <Modal open={true} onClose={handleClose} sx={{ height: "100%" }}>
        <Box sx={style} className={styles.boxStyle}>
          <div className={styles.promises_detail_container}>
            <header className={styles.promises_header}>
              <div className={styles.promises_header_start}>
                <img
                  src={props?.promiseDetails?.displayMedia?.value?.url}
                  className={styles.promises_icon}
                />
                <h1 className={styles.promises_card_heading}>
                  {props?.promiseDetails?.name}
                </h1>
              </div>
              <CloseIcon
                className={styles.promises_close_icon}
                onClick={handleClose}
              />
            </header>

            <div className={styles.promises_description_content}>
              {props?.promiseDetails?.shortDescription}
            </div>
            <div className={styles.promises_list}>
              {props?.promiseDetails?.description &&
                props?.promiseDetails?.description.map(
                  (data: any, index: number) => {
                    return (
                      <li className={styles.promises_list_li}>
                        <img
                          src={MediaAssets.ic_check_circle}
                          className={styles.promises_list_icon}
                        />
                        <p className={styles.promises_list_data_detail}>
                          {data}
                        </p>
                      </li>
                    );
                  }
                )}
            </div>

            <div className={styles.promises_button} onClick={portfolioHandler}>
              <img
                src={MediaAssets.edit_active_icon}
                className={styles.promises_button_icon_style}
              />
              <div className={styles.promises_button_data}>
                <img
                  src={MediaAssets.ic_card_travel}
                  className={styles.promises_button_data_icon}
                />
                <Typography className={styles.promises_button_data_content}>
                  Explore All Projects
                </Typography>
              </div>
            </div>

            {/* <Link className={styles.read_link} to="#">
              Read our terms and condition
            </Link> */}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PromisesDetailWrapper;
