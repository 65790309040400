import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { getPreLoginPageAction } from "redux-container/pre-login-page-management-redux/PreLoginPageManagementRedux";

export function* preLoginpageManagementSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getPreLoginPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPreLoginPageAction.getPreLoginPagesSuccess(response.data));
    } else {
      yield put(getPreLoginPageAction.getPreLoginPagesFailure(response.data));
    }
  } catch (error) {
    yield put(getPreLoginPageAction.getPreLoginPagesFailure(error));
  }
}

export function* preLoginPromisePageSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPreLoginPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getPreLoginPageAction.getPreLoginPromiseSuccess(response.data));
    } else {
      yield put(getPreLoginPageAction.getPreLoginPromiseFailure(response.data));
    }
  } catch (error) {
    yield put(getPreLoginPageAction.getPreLoginPromiseFailure(error));
  }
}
