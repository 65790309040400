import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { MediaAndPrsActions } from "redux-container/media-and-pr/MediaAndPrRedux";

export function* getMediaAndPrs(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getMediaAndPrs, action.params);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(MediaAndPrsActions.getMediaAndPrsSuccess(response.data));
    } else {
      yield put(MediaAndPrsActions.getMediaAndPrsFailure(response.data));
    }
  } catch (error) {
    console.log("saga error", error);
    
    yield put(MediaAndPrsActions.getMediaAndPrsFailure(error));
  }
}
