import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { PageOneActions } from "redux-container/page1data-get-redux/getPage1data";

import { call, put } from "redux-saga/effects";

export function* getPageOneDataSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPreLoginPageDetails, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(PageOneActions.getPageOneDataSuccess(response.data));
    } else {
      yield put(PageOneActions.getPageOneDataFailure(response.data));
    }
  } catch (error) {
    yield put(PageOneActions.getPageOneDataFailure(error));
  }
}
